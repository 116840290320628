.c-grid-panels {
    $root: &;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;

    &__inner {
        width: 100%;
    }

    &__area {
        padding: 3rem 2rem;
        text-align: center;
        text-decoration: none;
        display: block;
    }

    &__area:hover {
        text-decoration: none;
        box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.16);

        .c-arrow-button {
            .c-icon {
                right: -0.8rem;
                transition: all 0.2s ease-in-out;
            }
        }
    }

    &__title {
        position: relative;
        margin-bottom: 2rem;
        padding-bottom: 2rem;

        h2 {
            margin-bottom: 0;
            color: $color-blue-aqua;
        }

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            height: 1px;
            width: 113px;
            background-color: $color-black;
        }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        &__row {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: center;

            #{$root}__area {
                &:nth-child(1), &:nth-child(4), &:nth-child(5), &:nth-child(8), &:nth-child(9) {
                    background-color: rgba($color-grey-dark, 0.05);
                }
            }
        }

        &__area {
            padding: 4rem 2.4rem;
            width: 50%;
        }
    }

    @include min(1024px) {
        &__row {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: center;

            &:nth-child(2n) {
                #{$root}__area {
                    &:nth-child(2n) {
                        background-color: rgba($color-grey-dark, 0.05);
                    }
                }
            }

            &:nth-child(2n + 1) {
                #{$root}__area {
                    &:nth-child(2n + 1) {
                        background-color: rgba($color-grey-dark, 0.05);
                    }
                }
            }
        }

        &__area {
            padding: 4rem 2.4rem;
            width: 33.3%;
        }
    }

    @include max(767px) {
        &__row {
            &:nth-child(2n) {
                #{$root}__area {
                    &:nth-child(2n) {
                        background-color: rgba($color-grey-dark, 0.05);
                    }
                }
            }

            &:nth-child(2n + 1) {
                #{$root}__area {
                    &:nth-child(2n + 1) {
                        background-color: rgba($color-grey-dark, 0.05);
                    }
                }
            }
        }

        &__title {
            &:after {
                width: 115px;
            }
        }
    }
}
