/*
    Settings: GLOBAL

    These are the base settings used globally across the site.
*/

// Sizing and Spacing
$base-font-family: $font-montserrat;
$base-font-size: 16px;
$base-line-height: 1.3;

$base-radius: 8px;
$base-gutter: 20px;

// Widths

$width-mobile-sm: 320px;
$width-mobile-lg: 420px;
$width-tablet-sm: 768px;
$width-tablet-lg: 1024px;
$width-desktop: 1280px;