﻿.c-page-intro {
    position: relative;
    max-width: 720px;
    width: 100%;
    font-weight: 500;
    @include font-size(16);
    color: $brand-orange;
    z-index: 1;

    @include min($width-tablet-sm) {
        @include font-size(19);
    }
}
