.c-trustee-board {
    $root: &;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;

    @include min($width-mobile-lg) {
        margin-top: 4.5rem;
    }

    @include min($width-tablet-sm) {
        justify-content: space-between;
        margin-top: 6rem;
    }

    &__inner {
        /*display: flex;
        flex-wrap: wrap;*/
        width: 100%;
        padding-bottom: 4.5rem;

        @include min($width-tablet-sm) {
            width: 50%;
            max-width: 600px;

            &:nth-child(2n) {
                padding-left: 1.5rem;
            }

            &:nth-child(2n + 1) {
                padding-right: 1.5rem;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__image {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        padding-bottom: 1.5rem;
        text-align: center;

        img {
            max-width: 260px;
        }

        @include min($width-tablet-sm) {
            max-width: 180px;
            padding-bottom: 0;
            padding-right: 2rem;
            text-align: left;

            img {
                max-width: 100%;
            }
        }

        @include min($width-tablet-lg) {
            max-width: 230px;
        }
    }

    &__info {
        display: inline-block;
        vertical-align: top;
        /*display: flex;
        flex-direction: column;*/
        // justify-content: space-between;
        width: 100%;
        padding-top: 1.5rem;
        text-align: center;

        @include min($width-tablet-sm) {
            width: calc(100% - 185px);
            padding-top: 0;
            padding-left: 2rem;
            text-align: left;
        }

        @include min($width-tablet-lg) {
            width: calc(100% - 235px);
        }
    }

    &__name {
        @include font-size(20);
        color: $brand-green;
        // text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 0.8rem;

        @include min($width-tablet-sm) {
            @include font-size(27);
        }
    }

    &__role {
        @include font-size(16);
        color: $color-green-deep;
        font-weight: 700;
        margin-bottom: 2rem;
        border-bottom: 1px solid rgba($color-grey-dark, 0.63);
        padding-bottom: 1.6rem;

        @include min($width-tablet-sm) {
            @include font-size(20);
        }
    }
}
