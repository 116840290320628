﻿/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox,
#cboxOverlay,
#cboxWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: hidden;
}

#cboxOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
    clear: left;
}

#cboxContent {
    position: relative;
}

#cboxLoadedContent {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

#cboxTitle {
    margin: 0;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
    cursor: pointer;
}

.cboxPhoto {
    float: left;
    margin: auto;
    border: 0;
    display: block;
    max-width: none;
    -ms-interpolation-mode: bicubic;
}

.cboxIframe {
    width: 100%;
    height: 100%;
    display: block;
    border: 0;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
    box-sizing: content-box;
}

/* 
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
    background: rgba(0, 0, 0, 0.8);
}

#colorbox {
    outline: 0;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
}

#cboxContent {
    background: #fff;
    overflow: hidden;
    border-radius: 3px;
    padding: 50px 0 20px;
}

.cboxIframe {
    background: #fff;
}

#cboxError {
    padding: 50px;
    border: 1px solid #ccc;
}

#cboxLoadedContent {
    margin-bottom: 28px;
}

#cboxTitle {
    position: absolute;
    bottom: 4px;
    left: 0;
    text-align: center;
    width: 100%;
    color: #949494;
}

#cboxCurrent {
    position: absolute;
    bottom: 4px;
    left: 58px;
    color: #949494;
}

#cboxLoadingOverlay {
    background: url(/images/colorbox/loading_background.png) no-repeat center center;
}

#cboxLoadingGraphic {
    background: url(/images/colorbox/loading.gif) no-repeat center center;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
    border: 0;
    padding: 0;
    margin: 0;
    overflow: visible;
    width: auto;
    background: none;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
    outline: 0;
}

#cboxSlideshow {
    position: absolute;
    bottom: 4px;
    right: 30px;
    color: #0092ef;
}

#cboxPrevious {
    position: absolute;
    bottom: 0;
    left: 0;
    background: url(/images/colorbox/controls.png) no-repeat -75px 0;
    width: 25px;
    height: 25px;
    text-indent: -9999px;
}

#cboxPrevious:hover {
    background-position: -75px -25px;
}

#cboxNext {
    position: absolute;
    bottom: 0;
    left: 27px;
    background: url(/images/colorbox/controls.png) no-repeat -50px 0;
    width: 25px;
    height: 25px;
    text-indent: -9999px;
}

#cboxNext:hover {
    background-position: -50px -25px;
}

#cboxClose {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    line-height: 1;
    color: #000;
    min-width: 0;
    text-transform: capitalize;
    font-size: 16px;
}

#cboxClose:after {
    content: '\00D7';
    font-size: 40px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}