.c-page-nav {
    display: inline-block;
    width: auto;

    &__btn {
        position: relative;
        height: 26px;
        width: 24px;
        min-width: 0;
        padding: 0;
        background-color: transparent;
        border: 0;
        color: transparent;
        border-radius: 0;
        text-indent: -9999px;

        @include min($width-tablet-sm) {
            height: 31px;
        }

        &:hover {
            background-color: transparent;
            border: 0;
            color: transparent;
        }

        &:focus {
            outline: 0;
        }

        &:before {
            content: "";
            opacity: 1;
            background-size: 20px auto;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            height: 100%;
        }

        &--prev {
            float: left;

            &:before {
                background-image: url("/images/icons/icon-arrow-left-grey.svg");
            }
        }

        &--next {
            float: right;

            &:before {
                background-image: url("/images/icons/icon-arrow-right-grey.svg");
            }
        }
    }

    &__text {
        display: inline-block;
        padding: 0 2rem;
        font-weight: 700;
        @include font-size(20);
        color: $brand-aqua;

        @include min($width-tablet-sm) {
            @include font-size(24);
        }
    }
}

.c-page-nav-container {
    position: relative;
    margin-bottom: 2rem;
    padding: 2rem 0;
    text-align: center;

    @include min($width-tablet-sm) {
        margin-bottom: 3rem;
    }
}
