.c-reactive-form-field {
    $root: &;
    position: relative;
    padding-top: 2rem;

    + #{$root} {
        padding-top: 0.6rem;

        &:last-child {
            margin-bottom: 2rem;
        }
    }

    input[type=text],
    input[type=number],
    input[type=email],
    input[type=password],
    textarea {
        display: inline-block;
        width: 100%;
    }

    input[type=text],
    input[type=number],
    input[type=email],
    input[type=password],
    textarea,
    select,
    .rddlInner {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 2px solid #dfe2e2;
        padding-left: 0;
        transition: border-color 0.2s ease-in-out;

        &:hover {
            border-bottom-color: #5E6D70;
        }

        &:-webkit-autofill,
        &:-moz-autofill,
        &:autofill {
            -webkit-animation: onAutoFill;
            animation: onAutoFill;
        }
    }

    label {
        position: relative;
        display: inline-block;
        padding-right: 0.8rem;
        color: $color-blue-aqua;
    }

    input[type=text],
    input[type=number],
    input[type=email],
    input[type=password] {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-right: 3.6rem;
        color: #5E5E5E;

        &:disabled {
            background-color: $color-white;
        }
    }

    &--inline {

        label,
        input[type=text],
        input[type=number],
        input[type=email],
        input[type=password],
        textarea {
            width: auto;
        }

        label {
            padding-right: 1rem;
        }
    }

    .autosize-textbox {
        min-height: 1em !important;
        height: 40px;
    }

    &--inline-radio {
        padding: 1rem 0em;
        margin-top: 20px;
    }

    &--inline-radio ul {
        display: inline-flex;
    }

    &--inline-radio li {
        padding-right: 20px;
        padding-top: 10px;
    }

    &--inline-radio li:last-child {
        padding-right: 0;
    }

    &--inline-radio li label {
        color: #8c8c8c;
    }

    &__errors-container {
        display: none;
    }

    &__error-message {
        display: inline-block;
        width: 100%;
        color: $brand-orange !important;
        font-weight: 500;
        @include font-size(16);

        @include min($width-tablet-sm) {
            margin-left: 3rem;
            width: auto;
        }
    }

    &__select {
        position: relative;
    }

    &__icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 100%;
        background-image: url('/images/icons/icon-arrow-down.png');
        background-size: 20px auto;
        background-position: center center;
        background-repeat: no-repeat;
    }

    &--checkbox,
    &--radio {

        input[type=checkbox],
        input[type=radio] {
            position: absolute;
            left: -9999px;

            + label {
                position: relative;
                padding: 1rem 1rem 1rem 3.5rem;
                color: #5E5E5E;

                &:before {
                    content: '';
                    background-color: $color-white;
                    border: 1px solid $color-grey;
                    position: absolute;
                    top: 9px;
                    left: 0px;
                    height: 18px;
                    width: 18px;
                }
            }
        }

        &#{$root}--float-right {
            display: inline-block;
            width: 100%;
            margin-top: 0.5rem;
            padding-top: 0;
            text-align: right;

            input[type=checkbox],
            input[type=radio] {
                + label {
                    padding-left: 0;
                    padding-right: 3.5rem;

                    &:before {
                        left: auto;
                        right: 0px;
                    }
                }
            }
        }
    }

    &--checkbox {
        input[type=checkbox] {
            &:checked + label:after {
                content: '';
                position: absolute;
                padding: 0;
                width: 12px;
                height: 12px;
                top: 12px;
                left: 3px;
                background-color: $brand-blue;
            }

            + label {
                font-weight: 400;
            }
        }

        &#{$root}--float-right {

            input[type=checkbox] {
                + label {
                    &:after {
                        left: auto;
                        right: 3px;
                    }
                }
            }
        }
    }

    &--radio {
        input[type=radio] {
            &:checked + label:after {
                content: '';
                position: absolute;
                padding: 0;
                background-color: #000000;
                width: 13px;
                height: 13px;
                top: 14px;
                left: 5px;
            }
        }

        input[type=radio] {
            + label {
                margin-right: 30px;

                &:before {
                    border-radius: 50%;
                }
            }

            &:checked + label:after {
                border-radius: 50%;
                content: '';
                position: absolute;
                padding: 0;
                background-color: #000000;
                width: 13px;
                height: 13px;
                top: 14px;
                left: 5px;
            }
        }
    }

    &--anim {

        label {
            // position: absolute;
            // left: 0;
            // color: #5E6D70;
            // line-height: 1.4;
            // pointer-events: none;
            // transition: transform .3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform .3s cubic-bezier(0.25, 0.1, 0.25, 1), color 0.3s ease-in-out;
            // -webkit-transform: none;
            // transform: none;
            // margin-top: 4px;
            // width: 100%;
            // -webkit-transform-origin: left;
            // transform-origin: left;
            // right: auto;
        }

        &.input-focused,
        &.select-focused,
        &.value-added {
            // label {
            //     color: $color-blue-aqua;
            //     -webkit-transform: scale(0.75, 0.75) translate(0, -26px);
            //     transform: scale(0.75, 0.75) translate(0, -26px);
            //     margin-top: auto;
            //     font-weight: 700;
            //     letter-spacing: 3px;
            //     text-transform: uppercase;
            //     width: 133%;
            // }
            // button,
            // input[type=button],
            // input[type=submit] {
            //     border-bottom: 0;
            // }
            // textarea {
            //     overflow: auto;
            //     min-height: 6em;
            // }
        }

        &.input-focused,
        &.select-focused {

            input[type=text],
            input[type=number],
            input[type=email],
            input[type=password],
            textarea,
            select {
                border-bottom-color: #145076;
            }
        }

        &.select-focused {
            #{$root}__icon {
                transform: rotate(180deg);
            }
        }

        &.value-added {
            // label {
            //     visibility: unset;
            // }
        }

        select {
            option {
                color: black;
            }
        }

        &:not(#{$root}--required) {
            &:after {
                content: none;
            }
        }
    }

    &--popout {

        input[type=text],
        input[type=number],
        input[type=email],
        input[type=password],
        textarea {
            padding-right: 190px;
        }

        .c-button {
            position: absolute;
            right: 0;
            bottom: 7px;
            padding: 8px 20px;
        }

        &#{$root}--required {
            &:after {
                right: 162px;
            }
        }
    }

    &__multi-inputs {
        width: 100%;

        label {
            display: inline-block;
            padding-right: 0;
            border-bottom: 2px solid #dfe2e2;
            transition: border-color 0.2s ease-in-out;

            &:hover {
                border-bottom-color: #5E6D70;
            }
        }

        select {
            padding-right: 3rem;
            border-bottom: 0;
        }

        &--inline {

            label,
            .RadDropDownList {
                display: inline-block;
                margin-right: 2rem;
                width: auto;

                &:last-child {
                    margin-right: 0;
                }
                // &:after {
                //     content: url("/images/icons/icon-arrow-dropdown-chervon-blue.svg");
                //     position: absolute;
                //     width: 24px;
                //     height: 100%;
                //     top: 0;
                //     right: 0;
                //     padding: 1.4rem 0;
                //     pointer-events: none;
                // }
            }
        }
    }

    &--required {

        &:after {
            content: "";
            position: absolute;
            bottom: 8px;
            right: 5px;
            background-size: 18px 18px;
            background-position: center center;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
        }

        &:not(#{$root}--error) {
            &.value-added {
                &:after {
                    background-image: url("/images/icons/icon-tick.svg");
                }
            }
        }

        div {
            display: inline;
        }
    }

    &--error {

        &:after {
            background-image: url("/images/icons/icon-cross-orange.svg");
            background-size: 16px 16px;
        }

        #{$root}__errors-container {
            display: block;
            width: 100%;
            margin-top: 1rem;
        }

        input[type=text],
        input[type=number],
        input[type=email],
        input[type=password],
        textarea,
        select {
            background-color: rgba($brand-orange, 0.2);
        }
    }

    &--invalid {

        &:after {
            background-image: none !important;
        }

        #{$root}__errors-container {
            display: block;
            width: 100%;
            margin-top: 1rem;
        }

        input[type=text],
        input[type=number],
        input[type=email],
        input[type=password],
        textarea,
        select,
        .date-droplist-child {
            background-color: rgba($brand-orange, 0.2);
        }

        &.value-added {
            .date-droplist-child {
                background-color: white;
            }

            &.c-date-droplist:after {
                background-image: url("/images/icons/icon-tick.svg") !important;
            }
        }
    }

    &--info-field {
        label {
            margin-bottom: 1rem;
        }

        p {
            &:last-child {
                margin-bottom: 0
            }
        }
    }
}

.required {
    color: $color-red;
}

.c-reactive-form-cta {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    padding-top: 1.4rem;

    &--centered {
        justify-content: center;
    }

    &--spaced {
        justify-content: space-between;
    }

    &__btn-wrap {
        padding: 1rem 2rem;
    }

    .c-button {
        padding: 8px 20px;
    }
}
