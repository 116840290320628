/*
    Settings: FONTS

    Custom fonts font-face declarations
*/

/*@font-face {
    font-family: "example";
    src: url("/fonts/example.eot?#iefix");
    src: url("/fonts/example.eot?#iefix") format("eot"),url("/fonts/example.woff2") format("woff2"),url("/fonts/example.woff") format("woff"),url("/fonts/example.ttf") format("truetype");
}*/

// $font-example: "example", serif;
$font-montserrat: "Montserrat", sans-serif, serif;
