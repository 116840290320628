.c-login {
    position: relative;
    width: 100%;
    @include font-size(16);
    z-index: 1;

    @include min($width-tablet-sm) {
        max-width: 512px;
    }

    h2 {
        display: inline-block;
        color: $color-blue-aqua;
        margin-bottom: 0.5rem;
    }

    a:not(.c-button) {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &__help-link {
        position: absolute;
        top: 8px;
        right: 0;
        display: inline-block;
    }
}
