.c-documents-list {
    display: flex;
    flex-wrap: wrap;
}

.c-documents-list-item {
    $root: &;
    position: relative;
    text-align: center;
    align-self: flex-start;
    width: 100%;
    padding: 2rem 0;

    @include min(500px) {
        padding: 2rem;
    }

    @include min($width-tablet-sm) {
        width: 50%;
    }

    @include min($width-tablet-lg) {
        width: 33.33%;
    }

    &:hover {
        background-color: rgba($color-black, 0.03);

        #{$root}__image {
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 106px;
                height: 106px;
                background-image: url("/images/icons/icon-documents-download.svg");
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto 100%;
                pointer-events: none;
            }
        }
    }

    &__image {
        position: relative;
        max-width: 285px;
        margin-left: auto;
        margin-right: auto;
        background-color: $color-white;
        border: 1px solid $color-grey;
        transition: border 0.2s ease-in-out;

        @include min($width-tablet-sm) {
            height: 330px;
        }
    }

    &__content {
        @include min($width-tablet-sm) {
            min-height: 52px;
        }
    }

    &__title {
        padding-top: 2rem;

        @include min($width-tablet-sm) {
            padding-top: 4rem;
        }

        h4 {
            color: $brand-aqua;
            font-weight: 700;
            @include font-size(20);
            margin-bottom: 0.5rem;
        }
    }

    &__sub-title {
        h5 {
            color: $color-black;
            font-weight: 400;
            @include font-size(20);
            margin-bottom: 0.5rem;
        }
    }

    &__size {
        font-style: italic;
        @include font-size(16);
        color: $color-black;
    }

    a {
        font-weight: 700;
        font-size: 2rem;
        text-decoration: none;
        display: block;

        @include min($width-tablet-sm) {
            //
            position: relative;
            height: 100%;
        }

        &:hover {
            #{$root}__title {
                text-decoration: underline;
            }
        }
    }

    img {

        @include min($width-tablet-sm) {
            max-height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    iframe {
        height: 220px;
        max-width: 360px;
        margin: 0 auto;

        @include min($width-mobile-lg) {
            height: 300px;
        }

        @include min($width-tablet-sm) {
            height: 100%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .c-documents-list-item {
        &__title {
            @include font-size(16);
        }
    }
}

@media screen and (max-width:540px) {
    .c-documents-list-item {
        width: 100%;
    }
}
