.c-cookie-bar {
    position: fixed;
    height: auto;
    bottom: 0;
    width: 100%;
    padding: 2rem;
    background-color: #ffffff;
    border: 2px solid #000000;
    z-index: 100;


    @include min($width-tablet-sm) {
        max-width: 389px;
        bottom: 2rem;
        right: 4rem;
        left: auto;
        padding: 2.2rem 2rem;
    }

    &__hidden,
    &--hidden {
        height: 0;
        width: 0;
        padding: 0;
        margin: 0;
        border: 0;
        overflow: hidden;
    }

    &__message {
        margin-bottom: 1rem;

        p,
        a {
            color: $color-black;
        }

        p {
            @include font-size(16);
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        @include min($width-tablet-sm) {
            margin-bottom: 2rem;
        }
    }

    &__ctas {
        ul {
            display: flex;
            flex-wrap: nowrap;
            font-size: 0;
        }

        li {
            display: inline-block;

            &:first-child {
                flex-grow: 1;
            }
        }

        .c-button {
            min-width: 143px;
            background-color: $color-purple;
            border-color: $color-purple;
            color: $color-white;
            text-transform: uppercase;

            &:hover {
                background-color: $brand-purple-dark;
                border-color: $brand-purple-dark;
                color: $color-white;
            }

            &:focus {
                outline: 0;
            }
        }
    }
}
