﻿.c-site-header {
    $root: &;
    position: absolute;
    width: 100%;
    padding: 0;
    z-index: 3;
    background-color: transparent;
    padding-bottom: 15px;

    @include min($width-tablet-sm) {
        background-color: transparent;
        padding-bottom: 0;
    }

    @include min($width-tablet-lg) {
        height: 64px;
    }

    &__inner {
        width: 100%;
    }

    &__elements-wrap {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        @include min($width-tablet-lg) {
            justify-content: space-between;
        }
    }

    &__opts {
        width: auto;
    }

    &__nav-btn-container {
        display: flex;
        flex-wrap: wrap;
        // @include min($width-tablet-sm) {
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     z-index: 2;
        // }
        &--blend-blue {
            @include min($width-tablet-lg) {
                #{$root}__nav-btn {
                    background-color: $color-blue-aqua;

                    &:hover {
                        background-color: $color-blue-aqua;
                    }
                }
            }
        }

        &--blend-aqua {
            @include min($width-tablet-lg) {
                #{$root}__nav-btn {
                    background-color: $brand-aqua;

                    &:hover {
                        background-color: $brand-aqua;
                    }
                }
            }
        }

        &--blend-home {
            @include min($width-tablet-lg) {
                #{$root}__nav-btn {
                    background-color: $color-white !important;
                    color: $brand-blue !important;

                    &:hover {
                        background-color: $color-white !important;
                        color: $brand-blue !important;
                    }
                }
            }

            .c-icon,
            .c-icon:before,
            .c-icon:after {
                background-color: $brand-blue !important;
            }

            .c-site-header__nav-btn {
                background-color: $color-white !important;
            }
        }
    }

    &__nav-btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        line-height: 1.5;
        text-indent: -9999px;
        min-width: 0;
        width: 64px;
        height: 64px;
        background-color: $brand-aqua;
        color: $color-white;
        border: 0;
        border-radius: 0;
        @include font-size(18);
        font-weight: bold;
        text-transform: uppercase;
        overflow: hidden;

        &:hover,
        &:focus {
            outline: 0;
        }

        &:hover {
            background-color: $brand-aqua;
        }

        .c-icon:before,
        .c-icon:after {
            content: "";
        }

        .c-icon,
        .c-icon:before,
        .c-icon:after {
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            width: 33px;
            height: 6px;
            pointer-events: none;
            background-color: $color-white;
            border-radius: 10px;
        }

        .c-icon {
            top: calc(50% - 3px);
        }

        .c-icon:before {
            top: -10px;
        }

        .c-icon:after {
            top: 10px;
        }

        @include min($width-tablet-lg) {
            height: 70px;
            text-indent: 0;
            min-width: 150px;
            padding: 20px 20px 10px 20px;
        }
    }

    &__nav-btn-text {
        display: none;

        @include min($width-tablet-lg) {
            display: block;
        }
    }

    &__nav-btn-inner {
        position: relative;
        display: block;
        width: 33px;
        height: 26px;

        @include min($width-tablet-lg) {
            margin-left: 23px;
        }
    }

    &__home-btn {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-right: 15px;
        padding: 4px 4px 8px 4px;
        width: 47px;
        height: 64px;
        background-color: $brand-blue;

        @include min($width-tablet-lg) {
            height: 70px;
        }

        .c-icon {
            width: 24px;
            height: 24px;
            fill: $color-white;
            transition: transform 0.3s ease-in-out;
        }
    }

    &__login-btn {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-right: 15px;
        padding: 4px 15px 18px 15px;
        width: auto;
        height: 64px;
        background-color: $brand-blue;
        color: #fff;
        font-weight: bold;
        @include font-size(18);
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        @include min($width-tablet-lg) {
            height: 70px;
        }
    }
}

.o-main-content--shaded {
    .c-site-header {
        background-color: $color-white;

        @include min($width-tablet-sm) {
            background-color: transparent;
        }
    }
}

.o-outer-page--landing {
    .c-site-header {
        @include min($width-tablet-lg) {
            padding: 0 2rem;
        }

        &__inner {
            @include min($width-tablet-lg) {
                padding: 5rem 0;
            }
        }
    }
}
