.c-page-top-btn {
    position: fixed;
    bottom: 6rem;
    right: 0;
    width: 38px;
    height: 38px;
    z-index: 2;

    @include min($width-tablet-sm) {
        display: none;
    }

    &--hidden {
        display: none;
    }

    &__button {
        position: relative;
        background-color: rgba($color-grey, 0.24);
        min-width: 0;
        width: 100%;
        height: 100%;
        text-indent: -9999px;
        padding: 0;
        border: 0;
        border-radius: 0;
        background-image: url("/images/icons/icon-arrow-up-white.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 15px 18px;

        &:hover {
            background-color: rgba($color-grey, 0.70);
            border: 0;
        }
    }
}