.c-documents-scroller {
    position: relative;
    padding: 2rem 0;

    ul {
        font-size: 0;
    }

    li {
        display: inline-block;
        @include colNum(12);
        padding: 1rem 0 3rem 0;
    }

    @include min($width-tablet-sm) {
        padding: 3rem 0;

        ul {
            display: flex;
            flex-wrap: wrap;
        }

        li {
            @include colNum(6);
            padding: 2rem 3rem 3rem 3rem;
        }
    }

    &__title {
        text-align: center;
    }
}

.c-documents-scroller-item {
    $root: &;
    position: relative;
    text-align: center;
    width: 100%;

    @include min(600px) {
        height: 100%;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        float: left;
    }

    a {
        color: $color-black;
        width: 100%;

        &:focus {
            outline: 0;
        }

        @include min(645px) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__image-container {

        @include min(600px) {
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__inner {
        position: relative;
    }

    &__image {
        position: relative;
        display: block;
        height: 220px;
        width: 100%;
        max-width: 285px;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        background-color: $color-white;
        border: 1px solid $color-grey;
        transition: border 0.2s ease-in-out;

        @include min(600px) {
            height: 270px;
        }

        img {
            max-height: 100%;
            display: block;
            margin: 0 auto;

            @include min(600px) {
                margin: auto;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }

    &__content {
        width: 100%;
        margin-top: auto;
        padding-top: 2rem;

        @include min(600px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        h4 {
            margin-bottom: 0;
            color: $color-black;
            width: 100%;
            @include font-size(18);
            font-weight: 400;
        }

        span {
            display: block;
            @include font-size(18);
            font-style: italic;
            color: $color-black;
            width: 100%;
        }
    }

    &__title {
        padding-top: 1rem;

        h3 {
            color: $color-white;
            @include font-size(22);
            transition: color 0.2s ease-in-out;

            a:hover {
                text-decoration: none;
            }
        }
    }
}