﻿%e-button--aqua {
    background-color: $brand-aqua;
    border: 2px solid $brand-aqua;
    color: $color-white;

    &:hover {
        background-color: $brand-aqua-dark;
        border-color: $brand-aqua-dark;
        color: $color-white;
    }
}

%e-button--purple {
    background-color: $brand-purple;
    border: 2px solid $brand-purple;
    color: $color-white;

    &:hover {
        background-color: $brand-purple-dark;
        border-color: $brand-purple-dark;
        color: $color-white;
    }
}

%e-button--purple-light {
    background-color: $color-purple;
    border: 2px solid $color-purple;
    color: $color-white;

    &:hover {
        background-color: $brand-purple-dark;
        border-color: $brand-purple-dark;
        color: $color-white;
    }
}

%e-button--orange {
    background-color: $brand-orange;
    border: 2px solid $brand-orange;
    color: $color-white;

    &:hover {
        background-color: $brand-orange-dark;
        border-color: $brand-orange-dark;
        color: $color-white;
    }
}

%e-button--orange-deep {
    background-color: $color-orange-deep;
    border: 2px solid $color-orange-deep;
    color: $color-white;

    &:hover {
        background-color: $color-orange-deep;
        border-color: $color-orange-deep;
        color: $color-white;
    }
}

%e-button--green {
    background-color: $brand-green;
    border: 2px solid $brand-green;
    color: $color-white;

    &:hover {
        background-color: $brand-green-dark;
        border-color: $brand-green-dark;
        color: $color-white;
    }
}

%e-button--green-deep {
    background-color: $color-green-deep;
    border: 2px solid $color-green-deep;
    color: $color-white;

    &:hover {
        background-color: $color-green-deep;
        border-color: $color-green-deep;
        color: $color-white;
    }
}

%e-button--dark-green {
    background-color: $color-green-deep;
    border: 2px solid $color-green-deep;
    color: $color-white;

    &:hover {
        background-color: $color-green-deep;
        border-color: $color-green-deep;
        color: $color-white;
    }
}

%e-button--reverse-blue {
    background-color: $color-white;
    border: 2px solid $color-white;
    color: $brand-blue;

    &:hover {
        background-color: $brand-blue;
        border-color: $color-white;
        color: $color-white;
    }
}

%e-button--reverse-black {
    background-color: $color-white;
    border: 2px solid $color-white;
    color: $color-black;

    &:hover {
        background-color: $color-black;
        border-color: $color-white;
        color: $color-white;
    }
}

%e-button--reverse-aqua {
    background-color: $color-white;
    border: 2px solid $color-white;
    color: $brand-aqua;

    &:hover {
        background-color: $brand-aqua;
        border-color: $color-white;
        color: $color-white;
    }
}

%e-button--reverse-orange {
    background-color: $color-white;
    border: 2px solid $color-white;
    color: $brand-orange;

    &:hover {
        background-color: $brand-orange;
        border-color: $color-white;
        color: $color-white;
    }
}

%e-button--reverse-orange-deep {
    background-color: $color-white;
    border: 2px solid $color-white;
    color: $color-orange-deep;

    &:hover {
        background-color: $color-orange-deep;
        border-color: $color-white;
        color: $color-white;
    }
}

%e-button--reverse-green {
    background-color: $color-white;
    border: 2px solid $color-white;
    color: $brand-green;

    &:hover {
        background-color: $brand-green;
        border-color: $color-white;
        color: $color-white;
    }
}

%e-button--reverse-purple {
    background-color: $color-white;
    border: 2px solid $color-white;
    color: $brand-purple;

    &:hover {
        background-color: $brand-purple;
        border-color: $color-white;
        color: $color-white;
    }
}

.c-button {
    @extend %e-button;
    @include font-size(16);

    &--large {
        padding: 1.6rem 2rem;
        min-width: 245px;
        border-radius: 0;
        margin-bottom: 2rem;

        @include min($width-tablet-sm) {
            margin-bottom: 3.6rem;
            padding: 1.8rem 4rem;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &--small {
        min-width: 0;
        padding: 0.6rem 2.7rem;
        line-height: 1;
    }

    &--aqua {
        @extend %e-button--aqua;
    }

    &--purple {
        @extend %e-button--purple;
    }

    &--purple-light {
        @extend %e-button--purple-light;
    }

    &--orange {
        @extend %e-button--orange;
    }

    &--green {
        @extend %e-button--green;
    }

    &--orange-deep {
        @extend %e-button--orange-deep;
    }

    &--green-deep {
        @extend %e-button--green-deep;
    }

    &--dark-green {
        @extend %e-button--dark-green;
    }

    &--reverse-blue {
        @extend %e-button--reverse-blue;
    }

    &--reverse-black {
        @extend %e-button--reverse-black;
    }

    &--reverse-purple {
        @extend %e-button--reverse-purple;
    }

    &--reverse-green {
        @extend %e-button--reverse-green;
    }

    &--reverse-orange {
        @extend %e-button--reverse-orange;
    }

    &--reverse-orange-deep {
        @extend %e-button--reverse-orange-deep;
    }

    &--reverse-aqua {
        @extend %e-button--reverse-aqua;
    }

    &--overlay-open {
        cursor: pointer;
    }
}

.c-button-container {

    .c-button-wrap {
        display: inline-block;
        width: 100%;
        padding: 1.2rem 0;

        @include min($width-tablet-sm) {
            padding: 2rem;
        }

        .c-button {
            padding: 20px;
            max-width: 300px;
            width: 100%;
            @include font-size(24);

            @include min($width-tablet-sm) {
                max-width: none;
                width: auto;
            }
        }
    }

    &--consistant-height {
        justify-content: center;
        max-width: 270px;
        margin: 0 auto;

        .c-button-wrap {

            .c-button {
                display: table-cell;
                height: 100px;
                vertical-align: middle;
                margin: 0 auto;
                width: 1%;
                padding: 0 30px;
            }
        }

        @include min($width-tablet-sm) {
            max-width: 1000px;
            flex-wrap: nowrap;
        }
    }
}


.c-arrow-button {
    color: #0055AD;
    position: relative;
    padding-right: 40px;
    text-align: left;
    cursor: pointer;
    font-weight: bold;
    @include font-size(16);

    .c-icon {
        position: absolute;
        top: 50%;
        left: auto;
        right: 0;
        transform: translate(0, -50%);
        width: 27px;
        height: 27px;
        fill: #30BFE1;
        color: #30BFE1;
        transition: all 0.2s ease-in-out;
    }

    &--purple {
        .c-icon {
            fill: $color-purple;
            color: $color-purple;
        }
    }

    &--orange {
        .c-icon {
            fill: $brand-orange;
            color: $brand-orange;
        }
    }

    &--green {
        .c-icon {
            fill: $brand-green;
            color: $brand-green;
        }
    }

    &--green-deep {
        .c-icon {
            fill: $color-green-deep;
            color: $color-green-deep;
        }
    }

    &--dark-green {
        .c-icon {
            fill: $color-green-deep;
            color: $color-green-deep;
        }
    }

    &--light-green {
        .c-icon {
            fill: #C6C700;
            color: #C6C700;
        }
    }

    &--dark-blue {
        .c-icon {
            fill: #4AB8CD;
            color: #4AB8CD;
        }
    }
}
