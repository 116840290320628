.c-promo-panel {
    $root: &;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-top: 3rem;

    @include min($width-tablet-sm) {
        padding: 3rem 0 2rem;
    }

    @include min($width-tablet-lg) {
        margin-bottom: 2rem;
        padding: 0;
    }

    &__area {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-height: 250px;
        margin-bottom: 2rem;
        overflow: hidden;
        z-index: 2;

        &:last-child {
            margin-bottom: 0;
        }

        @include min($width-tablet-sm) {
            width: 50%;
            margin-bottom: 0;
            padding: 0 2rem 4rem 2rem;
        }

        @media only screen and (min-width: $width-tablet-sm) and (max-width: $width-tablet-lg - 1) {
            padding-left: 0;
            padding-right: 0;

            &:nth-child(2n + 1) {
                padding-right: 2rem;
            }

            &:nth-child(2n) {
                padding-left: 2rem;
            }
        }

        @include min($width-tablet-lg) {
            padding: 0;
            width: 100%;
            height: 163px;
            margin-top: 34px;
            min-height: 0;

            &:nth-child(1) {
                #{$root}__content {
                    width: 970px;
                }

                + #{$root}__image {
                    height: 163px;
                    background-position: center right;

                    &:before {
                        right: calc(100% - 1140px);
                    }
                }
            }

            &:nth-child(2) {
                #{$root}__content {
                    width: 718px;
                }

                + #{$root}__image {
                    height: 360px;
                    background-position: center right;

                    &:before {
                        right: calc(100% - 1015px);
                    }
                }
            }

            &:nth-child(3) {
                #{$root}__content {
                    width: 465px;
                }

                + #{$root}__image {
                    height: 557px;
                    background-position: center right;

                    &:before {
                        // right: calc(100% - 590px);
                        right: calc(100% - 880px);
                    }
                }
            }
        }

        @include min(1300px) {
            &:nth-child(1) {
                + #{$root}__image {
                    &:before {
                        right: calc(83.2% - 518px);
                    }
                }
            }

            &:nth-child(2) {
                + #{$root}__image {
                    &:before {
                        right: calc(83.2% - 388px);
                    }
                }
            }

            &:nth-child(3) {
                + #{$root}__image {
                    &:before {
                        right: calc(83.2% - 258px);
                    }
                }
            }
        }

        &--reverse-orange {

            #{$root}__content {
                background-color: #F6F6F6;

                &:before {
                    background-color: #F6F6F6;
                }
            }

            #{$root}__title {
                h2 {
                    color: $brand-orange;
                }
            }
        }

        &--reverse-purple {

            #{$root}__content {
                background-color: #F6F6F6;

                &:before {
                    background-color: #F6F6F6;
                }
            }

            #{$root}__title {
                h2 {
                    color: $brand-purple;
                }
            }
        }

        &--reverse-green {

            #{$root}__content {
                background-color: #F6F6F6;

                &:before {
                    background-color: #F6F6F6;
                }
            }

            #{$root}__title {
                h2 {
                    color: $brand-green;
                }
            }
        }

        &--reverse-blue {

            #{$root}__title {
                h2 {
                    color: $brand-blue;
                }
            }
        }

        &--blue {

            #{$root}__content {
                background-color: $brand-blue;

                &:before {
                    background-color: $brand-blue;
                }
            }

            #{$root}__title {
                h2 {
                    color: $color-white;
                }

                &:after {
                    background-color: $color-white;
                }
            }
        }

        &--blue-v2 {

            #{$root}__content {
                background-color: $color-blue-aqua;

                &:before {
                    background-color: $color-blue-aqua;
                }
            }

            #{$root}__title {
                h2 {
                    color: $color-white;
                }

                &:after {
                    background-color: $color-white;
                }
            }
        }

        &--aqua {

            #{$root}__content {
                background-color: $brand-aqua;

                &:before {
                    background-color: $brand-aqua;
                }
            }

            #{$root}__title {
                h2 {
                    color: $color-white;
                }

                &:after {
                    background-color: $color-white;
                }
            }
        }

        &--purple {

            #{$root}__content {
                background-color: $brand-purple;

                &:before {
                    background-color: $brand-purple;
                }
            }

            #{$root}__title {
                h2 {
                    color: $color-white;
                }

                &:after {
                    background-color: $color-white;
                }
            }
        }

        &--orange {

            #{$root}__content {
                background-color: $brand-orange;

                &:before {
                    background-color: $brand-orange;
                }
            }

            #{$root}__title {
                h2 {
                    color: $color-white;
                }

                &:after {
                    background-color: $color-white;
                }
            }
        }

        &--green {

            #{$root}__content {
                background-color: $brand-green;

                &:before {
                    background-color: $brand-green;
                }
            }

            #{$root}__title {
                h2 {
                    color: $color-white;
                }

                &:after {
                    background-color: $color-white;
                }
            }
        }

        &--green-deep {

            #{$root}__content {
                background-color: $color-green-deep;

                &:before {
                    background-color: $color-green-deep;
                }
            }

            #{$root}__title {
                h2 {
                    color: $color-white;
                }

                &:after {
                    background-color: $color-white;
                }
            }
        }
    }

    &__content {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 30px 20px;
        z-index: 1;
        background-color: #F6F6F6;

        @include min($width-tablet-lg) {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            padding: 50px 0 50px 30px;

            &:before {
                content: '';
                position: absolute;
                right: -106px;
                top: 0;
                display: block;
                height: 100%;
                width: 1000%;
                background-color: #F6F6F6;
                transform: skewX(-52deg);
                transform-origin: 100%;
                z-index: -1;
            }
        }
    }

    &__image {
        position: relative;
        height: 100%;
        background-position: bottom right;
        background-size: auto 140px;
        background-repeat: no-repeat;
        background-color: $color-white;
        overflow: hidden;

        &:before {
            content: "";
            position: absolute;
            width: 34px;
            height: 100%;
            background-color: $color-white;
            z-index: 1;
            transform: skewX(-52deg);
            transform-origin: 100%;
        }

        @include min($width-tablet-lg) {
            position: absolute;
            right: 0;
            top: 0;
            height: 557px;
            // width: calc(100% - 288px);
            width: 100%;
            margin-top: 34px;
            background-position: center right;
            // background-size: auto 100%;
            background-size: auto 100%;

            &:before {
                top: 0;
                right: calc(100% - 590px);
                width: 1000%;
            }
        }

        @include min(1300px) {
            width: 60%;
            background-size: cover;
        }
    }

    &__title {
        position: relative;
        width: 100%;
        margin-bottom: 2.2rem;
        padding: 2rem 0;
        align-self: flex-end;

        h2 {
            margin-bottom: 0;
            color: $color-black;
            letter-spacing: 0;
        }

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            width: 150px;
            height: 1px;
            background-color: $color-black;
        }

        @include min($width-tablet-sm) {
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 1rem;
            align-self: unset;

            h2 {
                margin-bottom: 1rem;
            }
        }

        @include min($width-tablet-lg) {
            width: auto;

            &:after {
                margin-left: 0;
                margin-right: 0;
            }
        }

        &--0-bottom-spacing {
            padding-bottom: 0;

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:after {
                content: none;
            }
        }
    }

    &__cta {
        position: relative;
        width: 100%;
        align-self: flex-start;

        @include min($width-tablet-sm) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            align-self: unset;
        }

        @include min($width-tablet-lg) {
            position: relative;
            width: auto;
            margin-left: 0;
            margin-right: 0;
        }

        .c-button {
            min-width: 143px;
        }
    }

    &__box {
        position: relative;
        width: 100%;
        height: 100%;
        color: $color-white;
        text-align: center;

        @include min($width-tablet-lg) {
            text-align: left;
        }

        h1 {
            margin-bottom: 0;
        }
    }

    &__box-inner {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: 100%;

        @include min($width-tablet-sm) {
            display: block;
            padding-bottom: 7rem;
        }

        @include min($width-tablet-lg) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: auto;
            padding-bottom: 0;
        }
    }

    &--main {
        @include min($width-tablet-sm) {
            padding: 0;

            #{$root}__area {
                width: 100%;
                padding-bottom: 0;

                &:nth-child(1) {
                    #{$root}__content {
                        width: 240px;

                        &:before {
                            content: '';
                            position: absolute;
                            right: -190px;
                            top: 0;
                            display: block;
                            height: 100%;
                            width: 1000%;
                            background-color: $color-blue-aqua;
                            transform: skewX(-52deg);
                            transform-origin: 100%;
                            z-index: -1;
                        }
                    }
                }
            }

            #{$root}__content {
                padding: 80px 20px;
            }

            #{$root}__image {
                position: absolute;
                right: 0;
                top: auto;
                bottom: 0;
                height: 557px;
                width: 100%;
                margin-top: 34px;
                background-position: center right;
                /*background-size: auto 100%;*/
                background-size: cover;
                z-index: 0;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: calc(100% - 650px);
                    width: 1000%;
                    height: 100%;
                    background-color: $color-white;
                    z-index: 1;
                    transform: skewX(-52deg);
                    transform-origin: 100%;
                }
            }

            #{$root}__box {
                text-align: left;
            }
        }

        @include min($width-tablet-lg) {
            #{$root}__area {
                &:nth-child(1) {
                    #{$root}__content {
                        width: 718px;

                        &:before {
                            right: -106px;
                        }
                    }

                    + div > #{$root}__image {
                        height: 557px;
                        top: auto;
                        bottom: 0;
                        z-index: 0;

                        &:before {
                            right: calc(100% - 422px);
                        }
                    }
                }
            }

            #{$root}__image {
                width: calc(100% - 720px);
            }

            #{$root}__content {
                padding: 50px 30px;
            }
        }

        @include min(1300px) {
            #{$root}__area {
                &:nth-child(1) {
                    + div > #{$root}__image {
                        &:before {
                            right: calc(100% - 422px);
                        }
                    }
                }
            }

            #{$root}__image {
                width: calc(50% - 70px);
            }
        }
    }
}

.o-outer-page--landing {
    .c-promo-panel {
    }
}
