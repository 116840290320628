﻿@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: $color-grey !default;
$slick-dot-color-active: white !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }

    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }

    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("images/icons/ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family=="slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("fonts/slick/slick.eot");
        src: slick-font-url("fonts/slick/slick.eot?#iefix") format("embedded-opentype"), slick-font-url("fonts/slick/slick.woff") format("woff"), slick-font-url("fonts/slick/slick.ttf") format("truetype"), slick-font-url("fonts/slick/slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-arrow {
    position: absolute;
    top: calc(50% - 40px);
}

.slick-prev,
.slick-next {
    height: 50px;
    width: 30px;
    min-width: 0;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    padding: 0;
    border: none;
    outline: none;
    z-index: 1;

    &:hover,
    &:focus {
        outline: none;
        background: transparent;
        color: transparent;

        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }

    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }

    &:before {
        /*font-family: $slick-font-family;
        font-size: 20px;*/
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-size: auto 100%;
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 100%;
    }
}

.slick-prev {
    left: -1rem;

    @include min($width-tablet-sm) {
        left: -2rem;
    }

    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }

    &:before {
        /*content: $slick-prev-character;*/
        content: "";
        background-image: url("/images/icons/icon-arrow-left.svg");

        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: -1rem;

    @include min($width-tablet-sm) {
        right: -2rem;
    }

    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }

    &:before {
        /*content: $slick-next-character;*/
        content: "";
        background-image: url("/images/icons/icon-arrow-right.svg");

        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.slick-dots {
    position: relative;
    list-style: none;
    display: inline-block;
    text-align: center;
    padding: 0;
    margin: 0 3px;
    width: auto;
    line-height: 22px;

    li {
        position: relative;
        display: inline-block;
        height: 18px;
        width: 18px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            display: block;
            height: 18px;
            width: 18px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            cursor: pointer;
            position: relative;
            border-radius: 50%;
            border: 1px solid $color-grey;
            background-color: $slick-dot-color;
            opacity: $slick-opacity-default;

            &:hover,
            &:focus {
                outline: none;
            }
        }

        &.slick-active button {
            background-color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}




.c-carousel-nav-below {

    /* Slider */

    .slick-list {
        .slick-loading & {
            background: #fff slick-image-url("images/icons/ajax-loader.gif") center center no-repeat;
        }
    }

    /* Icons */
    @if $slick-font-family=="slick" {
        @font-face {
            font-family: "slick";
            src: slick-font-url("fonts/slick/slick.eot");
            src: slick-font-url("fonts/slick/slick.eot?#iefix") format("embedded-opentype"), slick-font-url("fonts/slick/slick.woff") format("woff"), slick-font-url("fonts/slick/slick.ttf") format("truetype"), slick-font-url("fonts/slick/slick.svg#slick") format("svg");
            font-weight: normal;
            font-style: normal;
        }
    }

    /* Arrows */

    .slick-prev,
    .slick-next {
        height: 22px;
        width: 22px;
        line-height: 0px;
        font-size: 0px;
        cursor: pointer;
        background: transparent;
        color: transparent;
        padding: 0;
        border: none;
        outline: none;
        position: relative;
        left: auto;
        right: auto;
        display: inline-block;

        &:hover,
        &:focus {
            outline: none;
            background: transparent;
            color: transparent;

            &:before {
                opacity: $slick-opacity-on-hover;
            }
        }

        &.slick-disabled:before {
            opacity: $slick-opacity-not-active;
        }

        &:before {
            /*font-family: $slick-font-family;
            font-size: 20px;*/
            line-height: 1;
            color: $slick-arrow-color;
            opacity: $slick-opacity-default;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-size: 12px 20px;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            height: 100%;
        }
    }

    .slick-prev {
        float: left;

        [dir="rtl"] & {
            left: auto;
            right: -25px;
        }

        &:before {
            /*content: $slick-prev-character;*/
            content: "";
            background-image: url("/images/icons/icon-arrow-left.svg");

            [dir="rtl"] & {
                content: $slick-next-character;
            }
        }
    }

    .slick-next {
        float: right;

        [dir="rtl"] & {
            left: -25px;
            right: auto;
        }

        &:before {
            /*content: $slick-next-character;*/
            content: "";
            background-image: url("/images/icons/icon-arrow-right.svg");

            [dir="rtl"] & {
                content: $slick-prev-character;
            }
        }
    }

    /* Dots */

    .slick-dotted.slick-slider {
        padding-bottom: 50px;
    }

    .slick-dots {
        position: relative;
        list-style: none;
        display: inline-block;
        text-align: center;
        padding: 0;
        margin: 0 3px;
        width: auto;
        line-height: 22px;

        li {
            position: relative;
            display: inline-block;
            height: 18px;
            width: 18px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button {
                border: 0;
                display: block;
                height: 18px;
                width: 18px;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                cursor: pointer;
                position: relative;
                border-radius: 50%;
                border: 1px solid $color-grey;
                background-color: $slick-dot-color;
                opacity: $slick-opacity-default;

                &:hover,
                &:focus {
                    outline: none;
                }
            }

            &.slick-active button {
                background-color: $slick-dot-color-active;
                opacity: $slick-opacity-default;
            }
        }
    }

    .slider-nav-container {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        z-index: 3;
        text-align: center;

        >.slider-nav {
            font-size: 0;
            display: inline-block;
            position: relative;
        }
    }


}

.c-carousel-container {

    &--large,
    &--small {
        >ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            >li {
                max-width: 436px;
                width: 100%;
                padding: 3rem;
            }
        }

        .slick-slide {
            @include min(645px) {
                max-width: 436px;
                width: 100%;
            }
        }
    }
}