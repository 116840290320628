.c-accordion {
    $root: &;
    position: relative;
    margin-bottom: 2.5rem;

    &:last-child {
        margin-bottom: 0;
    }

    &--hidden {
        > #{$root}__toggle {

            &:before {
                transform: rotate(-90deg);
                right: 1.8rem;
            }
        }

        > #{$root}__detatched-toggle #{$root}__toggle {

            &:before {
                transform: rotate(-90deg);
            }
        }
    }

    & > #{$root}__detatched-toggle {
        display: flex;
        justify-content: space-between;

        > #{$root}__toggle {
            width: 41px;
            height: 39px;
            padding: 0;

            &:before {
                right: calc(50% - 10px);
                border-width: 10px;
            }
        }
    }

    &__link {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        line-height: 1.2;
        @include font-size(16);
        border-right: 1px solid $color-white;
    }

    &__toggle {
        position: relative;
        display: block;
        width: 100%;
        color: $color-white;
        background-color: $brand-blue;
        border-top: 2px solid $brand-blue;
        border-left: 2px solid $brand-blue;
        border-right: 2px solid $brand-blue;
        border-bottom: 2px solid $brand-blue;
        border-radius: 0;
        text-align: left;
        @include font-size(16);
        font-family: $base-font-family;
        font-weight: 700;
        min-height: 42px;
        padding: 0.9rem 6rem 0.9rem 1.4rem;
        line-height: 1.3;
        letter-spacing: 1.2px;
        outline: none;

        @include min($width-tablet-sm) {
            min-height: 43px;
            padding: 1.2rem 6rem 1.2rem 2.5rem;
        }

        &:hover {
            text-decoration: none;

            &:before {
                right: 1.4rem;
            }
        }

        &:before {
            content: '';
            position: absolute;
            right: 1.6rem;
            top: calc(50% - 8px);
            width: 24px;
            height: 16px;
            transform: rotate(0deg);
            transition: transform 0.2s ease-in-out, right 0.2s ease-in-out;
            background-image: url("/images/icons/icon-arrow-dropdown-white.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: auto 100%;
        }

        &--hide-sm-tablet {
            @include min($width-tablet-sm) {
                display: none;
            }

            + #{$root}__view {
                @include min($width-tablet-sm) {
                    max-height: none !important;

                    > #{$root}__view-box {
                        border: 0;
                    }
                }
            }
        }

        &--hide-lg-tablet {
            @include min($width-tablet-lg) {
                display: none;
            }

            + #{$root}__view {
                @include min($width-tablet-lg) {
                    max-height: none !important;

                    > #{$root}__view-box {
                        border: 0;
                    }
                }
            }
        }
    }

    &__view {
        transition: max-height 0.2s ease-in-out;
        overflow: hidden;
        max-height: 0;
        text-align: left;

        ul {
            list-style: none;
            padding-left: 2.6rem;

            > li {
                position: relative;
                padding: 0.6rem 0;

                &:before {
                    content: "";
                    position: absolute;
                    top: 1.1rem;
                    left: -2.5rem;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    background-color: #BBBCBE;
                }
            }
        }
    }

    &__view-box {
        padding: 2.5rem 1.8rem;
        background-color: $color-white;
        border-left: 2px solid $brand-blue;
        border-right: 2px solid $brand-blue;
        border-bottom: 2px solid $brand-blue;

        @include min($width-tablet-sm) {
            padding: 2.5rem 2rem;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    &--false {
        > #{$root}__toggle {

            &:before {
                transform: rotate(0deg);
            }

            &:after {
                transform: rotate(0deg);
                top: calc(50% - 9px);
                left: calc(100% - 25px);
            }
        }
    }

    &--general {

        > #{$root}__view {
            > #{$root}__view-box {
                padding: 8px 0;
            }
        }
    }

    &--primary {
        & > #{$root}__detatched-toggle {
            background-color: $brand-blue;
            border: 2px solid $brand-blue;
            color: $color-white;

            a {
                color: $color-white;
            }
        }

        #{$root}__toggle {
            background-color: $brand-blue;
            border-color: $brand-blue;
            color: $color-white;
        }
    }

    &--secondary {
        & > #{$root}__detatched-toggle {
            background-color: $color-blue-aqua;
            border: 2px solid $color-blue-aqua;
            color: $color-white;

            a {
                color: $color-white;
            }
        }

        #{$root}__toggle {
            background-color: $color-blue-aqua;
            border-color: $color-blue-aqua;
            color: $color-white;
        }

        > #{$root}__view {
            > #{$root}__view-box {
                border-color: $color-blue-aqua;
            }
        }
    }

    &--nav-bar {

        @include min($width-tablet-lg) {
            border-bottom: 0;
        }

        > #{$root}__toggle {
            padding: 1.2rem 6rem 1.2rem 3rem;
            color: $color-black;
            border: 0;
        }

        > #{$root}__view {
            > #{$root}__view-box {
                padding: 0;
                background-color: transparent;
                border: 0;
            }
        }
    }

    &--small {
        &#{$root}--hidden {
            > #{$root}__toggle {
                &:before {
                    right: 0.7rem;
                    transform: rotate(0deg);
                }
            }
        }

        > #{$root}__toggle {
            padding: 0.6rem 4rem 0.6rem 2rem;
            min-height: 0;

            &:before {
                background-image: url("/images/icons/icon-arrow-right-white.svg");
                right: 0.9rem;
                transform: rotate(90deg);
            }
        }
    }

    &--mob-nav-bar {

        @include min($width-tablet-lg) {
            border-bottom: 0;
        }

        > #{$root}__toggle {
            padding: 1.2rem 6rem 1.2rem 3rem;
            color: $color-black;
            border: 0;
        }

        > #{$root}__view {
            > #{$root}__view-box {
                padding: 0;
                background-color: transparent;
                border: 0;
            }
        }
    }

    &--nav-dropdown {
        > #{$root}__toggle {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 1.8rem;
            background-color: $brand-blue;
            border: 2px solid $brand-blue;
            color: $color-white;
            @include font-size(22);
        }

        > #{$root}__view {
            ul {
                list-style: none;
                padding-left: 0;

                > li {
                    position: relative;
                    padding: 0;

                    &:before {
                        content: none;
                    }
                }
            }

            > #{$root}__view-box {
                padding-top: 1rem;
                padding-bottom: 1rem;
                padding-left: 0;
                padding-right: 0;

                a {
                    display: block;
                    width: 100%;
                    padding: 1rem 1.8rem;
                    font-weight: 700;
                    color: $color-black;

                    &:hover {
                        background-color: rgba($brand-aqua, 0.1);
                        text-decoration: none;
                    }
                }
            }
        }

        ul {
            margin-bottom: 0;
        }
    }

    &--orange {
        #{$root}__toggle {
            background-color: $brand-orange;
            border-color: $brand-orange;
            color: $color-white;
        }

        > #{$root}__view {
            > #{$root}__view-box {
                border-color: $brand-orange;
            }
        }
    }

    &--purple {
        #{$root}__toggle {
            background-color: $brand-purple;
            border-color: $brand-purple;
            color: $color-white;
        }

        > #{$root}__view {
            > #{$root}__view-box {
                border-color: $brand-purple;
            }
        }
    }

    &--green {
        #{$root}__toggle {
            background-color: $brand-green;
            border-color: $brand-green;
            color: $color-white;
        }

        > #{$root}__view {
            > #{$root}__view-box {
                border-color: $brand-green;
            }
        }
    }

    &--green-deep {
        #{$root}__toggle {
            background-color: $color-green-deep;
            border-color: $color-green-deep;
            color: $color-white;
        }

        > #{$root}__view {
            > #{$root}__view-box {
                border-color: $color-green-deep;
            }
        }
    }

    &--blue-reverse {
        border-bottom: 1px solid $brand-blue;

        #{$root}__toggle {
            background-color: $color-white;
            border-width: 1px;
            border-color: $brand-blue;
            border-bottom: 0;
            color: $brand-blue;

            &:before {
                background-image: url("/images/icons/icon-arrow-dropdown-blue.svg");
            }
        }

        > #{$root}__view {
            padding-left: 2rem;
            padding-right: 2rem;
            border-left: 1px solid $brand-blue;
            border-right: 1px solid $brand-blue;

            > #{$root}__view-box {
                padding-top: 1.5rem;
                padding-left: 0;
                padding-right: 0;
                border: 0;
                border-top: 1px solid $brand-blue;
                border-bottom: 0;
            }
        }
    }

    &--purple-reverse {
        border-bottom: 1px solid $brand-purple;

        #{$root}__toggle {
            background-color: $color-white;
            border-width: 1px;
            border-color: $brand-purple;
            border-bottom: 0;
            color: $brand-purple;

            &:before {
                background-image: url("/images/icons/icon-arrow-dropdown-purple.svg");
            }
        }

        > #{$root}__view {
            padding-left: 2rem;
            padding-right: 2rem;
            border-left: 1px solid $brand-purple;
            border-right: 1px solid $brand-purple;

            > #{$root}__view-box {
                padding-top: 1.5rem;
                padding-left: 0;
                padding-right: 0;
                border: 0;
                border-top: 1px solid $brand-purple;
                border-bottom: 0;
            }
        }
    }

    &--orange-reverse {
        border-bottom: 1px solid $brand-orange;

        #{$root}__toggle {
            background-color: $color-white;
            border-width: 1px;
            border-color: $brand-orange;
            border-bottom: 0;
            color: $brand-orange;

            &:before {
                background-image: url("/images/icons/icon-arrow-dropdown-orange.svg");
            }
        }

        > #{$root}__view {
            padding-left: 2rem;
            padding-right: 2rem;
            border-left: 1px solid $brand-orange;
            border-right: 1px solid $brand-orange;

            > #{$root}__view-box {
                padding-top: 1.5rem;
                padding-left: 0;
                padding-right: 0;
                border: 0;
                border-top: 1px solid $brand-orange;
                border-bottom: 0;
            }
        }
    }

    &--green-reverse {
        border-bottom: 1px solid $brand-green;

        #{$root}__toggle {
            background-color: $color-white;
            border-width: 1px;
            border-color: $brand-green;
            border-bottom: 0;
            color: $brand-green;

            &:before {
                background-image: url("/images/icons/icon-arrow-dropdown-green.svg");
            }
        }

        > #{$root}__view {
            padding-left: 2rem;
            padding-right: 2rem;
            border-left: 1px solid $brand-green;
            border-right: 1px solid $brand-green;

            > #{$root}__view-box {
                padding-top: 1.5rem;
                padding-left: 0;
                padding-right: 0;
                border: 0;
                border-top: 1px solid $brand-green;
                border-bottom: 0;
            }
        }
    }


    &--no-border {

        > #{$root}__view {
            border: 0;

            > #{$root}__view-box {
                border: 0;
            }
        }
    }
}

.c-accordions-container,
.c-navigation-bar {

    &--loading {

        .c-accordion {

            &__view {
                max-height: 0;
                overflow: hidden;
            }

            &__view,
            &__toggle:after {
                transition: none;
            }

            &__toggle:after {
                transform: rotate(-90deg);
            }
        }
    }
}

.c-accordions-container {
    margin-bottom: 2.5rem;

    &:last-child {
        margin-bottom: 0;
    }

    &--content-nav {

        .c-accordion {
            $root: &;

            &--primary {
                margin-bottom: 2rem;

                &:last-child {
                    margin-bottom: 0;
                }

                > .c-accordion__toggle--primary {
                    font-weight: 300;
                }

                > .c-accordion__view {
                    > .c-accordion__view-box {
                        padding: 2.5rem 0 0 0;
                        border: 0;
                    }
                }
            }

            &--secondary {

                &:last-child {
                    margin-bottom: 0;
                }
                // > .c-accordion__toggle--secondary {
                //     background-color: $color-white;
                //     border: 1px solid $color-text;
                //     color: $color-black;
                //     font-weight: 400;
                // }
            }

            &--false {
                margin-bottom: 1.2rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__toggle {
                @include font-size(14);

                @include min($width-tablet-sm) {
                    @include font-size(16);
                }

                padding: 1rem 4rem 1rem 1.6rem;

                &:after {
                    left: calc(100% - 24px);
                }
            }
        }
    }
}
