.c-content {
    $root: &;

    .enlarged {
        @include font-size(44);

        @include min($width-tablet-sm) {
            @include font-size(50);
        }

        @include min($width-tablet-lg) {
            @include font-size(60);
        }
    }

    .bold {
        font-family: $base-font-family;
        font-weight: 700;
    }

    ul {
        list-style: none;
        padding-left: 3rem;

        > li {
            position: relative;
            padding: 0.8rem 0;

            &:before {
                content: "";
                position: absolute;
                top: 1.1rem;
                left: -2.9rem;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                background-color: $brand-aqua;
            }
        }
    }

    ol {
        counter-reset: item;

        li {
            position: relative;
            padding: 0.8rem 0;

            &:before {
                content: counters(item, ".") ". ";
                counter-increment: item;
                font-weight: 700;
                color: $brand-aqua;
                padding-right: 0.8rem;
            }
        }

        ol {
            margin-left: 1.5em;
        }
    }

    h4 {
        margin-top: 0;
        margin-bottom: 0;
    }

    &__list {
        > ul {
            list-style: none;
            padding-left: 2rem;

            > li {
                position: relative;
                padding: 0;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0.9rem;
                    left: -1.9rem;
                    width: 8px;
                    height: 2px;
                    background-color: $color-black;
                }
            }
        }
    }

    &__title {
        position: relative;
        display: block;
        text-align: center;
        @include font-size(35);
        margin-bottom: 3.4rem;
        padding-bottom: 2rem;
        color: $brand-blue;

        h1 {
            margin-bottom: 0
        }

        @include min($width-tablet-sm) {
            @include font-size(53);
        }

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            height: 1px;
            max-width: 244px;
            width: 100%;
            background-color: rgba($color-grey-dark, 0.63);
        }

        &--no-bar {
            margin-bottom: 0;

            &:after {
                content: none;
            }
        }
        /*&--smaller {
            margin-bottom: 0;

            h1 {
                @include font-size(35);
                letter-spacing: 2px;
            }

            &:after {
                content: none;
            }
        }

        &--small {
            @include font-size(31);
        }

        &--large {
            @include font-size(43);
        }

        @include min($width-tablet-sm) {
            &--smaller {
                h1 {
                    @include font-size(40);
                }
            }
        }*/
    }

    &__sub-title {
        color: $brand-aqua;
        text-transform: uppercase;
    }

    &__intro {
        color: $brand-aqua;
        font-weight: 700;
    }

    &--aqua {

        #{$root}__title,
        h1,
        h2,
        h3 {
            color: $brand-aqua;
        }

        #{$root}__intro {
            color: $brand-aqua;
        }

        ul {
            > li {
                &:before {
                    background-color: $brand-aqua;
                }
            }
        }

        ol {
            > li {
                &:before {
                    color: $brand-aqua;
                }
            }
        }
    }

    &--aqua-blue {

        #{$root}__title,
        h1,
        h2,
        h3 {
            color: #0071BA;
        }

        #{$root}__intro {
            color: $brand-aqua;
        }

        ul {
            > li {
                &:before {
                    background-color: $brand-aqua;
                }
            }
        }

        ol {
            > li {
                &:before {
                    color: $brand-aqua;
                }
            }
        }
    }

    &--orange {

        #{$root}__title,
        h1,
        h2,
        h3 {
            color: $brand-orange;
        }

        #{$root}__intro {
            color: $brand-orange;
        }

        ul {
            > li {
                &:before {
                    background-color: $brand-orange;
                }
            }
        }

        ol {
            > li {
                &:before {
                    color: $brand-orange;
                }
            }
        }
    }

    &--purple {

        #{$root}__title,
        h1,
        h2,
        h3 {
            color: $brand-purple;
        }

        #{$root}__intro {
            color: $brand-purple;
        }

        ul {
            > li {
                &:before {
                    background-color: $brand-purple;
                }
            }
        }

        ol {
            > li {
                &:before {
                    color: $brand-purple;
                }
            }
        }
    }

    &--purple-light {

        #{$root}__title,
        h1,
        h2,
        h3 {
            color: $color-purple;
        }

        #{$root}__intro {
            color: $color-purple;
        }

        ul {
            > li {
                &:before {
                    background-color: $brand-purple;
                }
            }
        }

        ol {
            > li {
                &:before {
                    color: $brand-purple;
                }
            }
        }
    }

    &--green {

        #{$root}__title,
        h1,
        h2,
        h3 {
            color: $brand-green;
        }

        #{$root}__intro {
            color: $brand-green;
        }

        ul {
            > li {
                &:before {
                    background-color: $brand-green;
                }
            }
        }

        ol {
            > li {
                &:before {
                    color: $brand-green;
                }
            }
        }
    }

    &--green-deep {

        #{$root}__title,
        h1,
        h2,
        h3 {
            color: $color-green-deep;
        }

        #{$root}__intro {
            color: $color-green-deep;
        }

        ul {
            > li {
                &:before {
                    background-color: $color-green-deep;
                }
            }
        }

        ol {
            > li {
                &:before {
                    color: $color-green-deep;
                }
            }
        }
    }
}
