.c-hero-banner {
    $root: &;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 210px;
    margin-bottom: 34px;
    overflow: hidden;
    background-repeat: no-repeat;
    /*background-size: auto 58%;*/
    /*background-size: cover;*/
    background-size: 100% auto;
    background-position: bottom right;

    @include min($width-tablet-sm) {
        height: 290px;
        background-position: center right;
        background-size: cover;
    }

    @include min($width-tablet-lg) {
        height: 290px;
    }

    &--under-header {
        z-index: 1;
        margin-top: 0;

        #{$root}__content {
            padding-top: 10px;
        }

        @include min(500px) {

            #{$root}__content {
                padding-top: 10px;
            }
        }

        @include min($width-tablet-sm) {

            #{$root}__content {
                padding-top: 70px;
            }
        }
    }

    &--0-top-spacing {
        padding-top: 0;
        margin-top: 0;

        #{$root}__content {
            padding-top: 40px;
        }

        @include min($width-tablet-sm) {
            #{$root}__content {
                padding-top: 70px;
            }
        }
    }

    &--large {
        @include min($width-tablet-sm) {
            height: 625px;

            #{$root}__image {
                height: 625px;
            }
        }
    }

    &--blue {

        #{$root}__content {
            &:before {
                background-color: $brand-aqua;
            }
        }
    }

    &--purple {

        #{$root}__content {
            &:before {
                background-color: $brand-purple;
            }
        }
    }

    &--orange {

        #{$root}__content {
            &:before {
                background-color: $brand-orange;
            }
        }
    }

    &--green {

        #{$root}__content {
            &:before {
                background-color: $brand-green;
            }
        }
    }

    &--green-deep {

        #{$root}__content {
            &:before {
                background-color: $color-green-deep;
            }
        }
    }

    &--no-bg {
        margin-bottom: 0;
        padding-bottom: 34px;
        height: auto;
        background-color: $color-white;
        background-image: none;

        #{$root}__content {
            width: 100%;
            padding-bottom: 4px;

            &:before {
                content: none;
            }

            &:after {
                content: none;
            }
        }

        #{$root}__title {
            color: $color-blue-aqua;
        }

        #{$root}__text {
            color: $color-blue-aqua;
            @include font-size(35);
            letter-spacing: 4px;

            @include min($width-tablet-sm) {
                @include font-size(52);
            }

            h1 {
                color: $color-blue-aqua;
                @include font-size(35);

                @include min($width-tablet-sm) {
                    @include font-size(52);
                }
            }
        }

        #{$root}__box {
            color: $color-blue-aqua;
        }

        #{$root}__box-inner {
            padding-bottom: 2rem;

            &:after {
                width: 94px;
                background-color: $color-black;
            }

            @include min($width-tablet-sm) {
                padding-bottom: 2.6rem;

                &:after {
                    width: 165px;
                }
            }
        }
    }

    &--home {
        height: auto;
        background-color: $color-white;
        background-image: none;
        padding-top: 70px;
        margin-bottom: 20px;

        #{$root}__logo {
            width: 100px;
            display: table-cell;
            vertical-align: middle;

            img {
                width: 80px;
            }
        }

        #{$root}__titles {
            display: table-cell;
            vertical-align: middle;
        }

        @include min($width-tablet-sm) {
            padding-top: 0;
        }

        @include max(550px) {
            #{$root}__logo {
                width: 100%;
                display: table-row;
                text-align: center;
            }

            #{$root}__titles {
                display: table-row;
                text-align: center;
            }
        }

        #{$root}__content {
            width: 100%;
            padding-bottom: 4px;

            &:before {
                content: none;
            }
            /*            &:after {
                content: "";
                background-color: $color-blue-aqua;
                position: absolute;
                top: 0;
                left: 912px;
                right: 0;
                height: 100%;
                width: 513px;
                z-index: 1;
                transform: skewX(-52deg);
            }
*/ // @include min($width-tablet-sm) {
            //     width: 75%;
            // }
            @include min($width-tablet-lg) {

                &:after {
                    width: 100%;
                    // left: 89%;
                    right: 0;
                }
            }

            @include min(1300px) {
                &:after {
                    left: calc(100% - 385px);
                    right: auto;
                }
            }
        }

        #{$root}__text {
            color: $color-blue-aqua;
            @include font-size(36);
            letter-spacing: 4px;

            @include min($width-tablet-sm) {
                @include font-size(36);
            }

            h1 {
                color: $color-blue-aqua;
                @include font-size(36);

                @include min($width-tablet-sm) {
                    @include font-size(36);
                }
            }
        }

        #{$root}__box {
            color: $color-blue-aqua;
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        width: 40%;
        height: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        z-index: 2;

        &:before {
            content: '';
            position: absolute;
            right: -105px;
            top: 0;
            display: block;
            height: 100%;
            width: 1000%;
            background-color: #54C0D3;
            transform: skewX(52deg);
            transform-origin: 100%;
            z-index: -1;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: -106px;
            width: 40px;
            height: 100%;
            transform: skewX(52deg);
            transform-origin: 100%;
            z-index: -1;
            background-color: $color-white;
        }

        @include min(425px) {
            width: 35%;
        }

        @include min(645px) {
            width: 30%;
        }

        @include min($width-tablet-sm) {
            width: 30%;
            padding-top: 50px;
            padding-bottom: 50px;

            &:before {
                right: -189px;
            }

            &:after {
                width: 60px;
                right: -189px;
            }
        }

        @include min($width-tablet-lg) {
            z-index: 1;
        }
    }

    &__image {
        position: relative;
        width: 75%;
        height: 100%;
        background-image: url("/images/template/header-template.png");
        background-position: bottom right;
        background-size: auto 140px;
        background-repeat: no-repeat;
        background-color: $color-white;

        &:before {
            content: "";
            position: absolute;
            left: 110px;
            width: 18px;
            height: 180%;
            top: calc(50% - 190px);
            background-color: $color-white;
            transform: rotate(-52deg);
            z-index: 1;
        }

        @include min($width-tablet-sm) {
            background-position: right center;
            background-size: cover;

            &:before {
                left: 190px;
                width: 28px;
                top: calc(50% - 266px);
            }
        }
    }

    &__box {
        position: relative;
        width: 100%;
        color: $color-white;
        text-align: left;

        h1 {
            margin-bottom: 0;
        }
    }

    &__box-inner {
        position: relative;
        padding-bottom: 1rem;
    }

    &__sub-title {
        margin-top: 0.5rem;
        letter-spacing: 1px;
        color: $color-black;
        @include font-size(14);

        @include min($width-tablet-sm) {
            @include font-size(18);
        }
    }

    &__text {
        display: block;
        font-weight: 700;
        @include font-size(20);
        color: $color-white;

        @include min($width-tablet-sm) {
            @include font-size(35);
        }

        h1 {
            display: block;
            font-weight: 700;
            @include font-size(20);
            color: $color-white;

            @include min($width-tablet-sm) {
                @include font-size(35);
            }
        }
    }
}

.o-main-content--shaded {
    .c-hero-banner {
        margin-bottom: 0;
        padding-bottom: 34px;
    }
}

.o-outer-page--landing {
    .c-hero-banner {
    }
}
