﻿.c-site-nav-container {
    position: absolute;
    background-color: $brand-black-light;
    overflow-x: auto;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 364px;
    height: 100%;
    z-index: 1;
    font-family: $base-font-family;

    &__inner {
        padding: 2rem;

        @include min($width-tablet-sm) {
            padding: 3rem 3.2rem;
        }

        @include min($width-desktop) {
            padding: 4rem 3.2rem;
        }
    }

    &__close {
        float: right;
    }

    &__close-btn {
        position: relative;
        min-width: 0;
        width: 40px;
        height: 40px;
        text-indent: -9999px;
        background-color: transparent;
        border: 0;
        border-radius: 0;

        &:hover,
        &:focus {
            outline: 0;
        }

        &:hover {
            background-color: transparent;
            border: 0;
        }

        .icon {
            position: static;
            top: 0;
            right: 0;
        }

        .icon:before,
        .icon:after {
            content: "";
            position: absolute;
            top: calc(50% - 2.5px);
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            width: 40px;
            height: 6px;
            pointer-events: none;
            background-color: $color-white;
            border-radius: 50px;
        }

        .icon:before {
            transform: rotate(45deg);
        }

        .icon:after {
            transform: rotate(-45deg);
        }
    }
}

.c-site-nav {
    padding-top: 1rem;
    padding-bottom: 2rem;

    a,
    button {
        display: block;
        width: 100%;
        text-align: left;
        color: $color-white;
        font-family: $base-font-family;
        font-weight: 700;
        @include font-size(15);

        &:hover {
            text-decoration: underline;
        }
    }

    button {

        &:hover,
        &:focus {
            outline: 0;
        }
    }

    &-search {
        position: relative;
        display: inline-block;
        width: 100%;
        margin-top: 3rem;

        input {
            @include font-size(14);
            text-transform: uppercase;
            padding: 0.9rem 45px 0.9rem 1.6rem;
            border-radius: 4px;
        }

        label {
            color: $color-black;
            display: none;
        }

        a {
            position: absolute;
            top: calc(50% - 13px);
            right: 6px;
            border-left: 1px solid $color-grey;
            height: 25px;
            width: 35px;
            text-indent: -9999px;
        }
    }

    ul {
        margin-bottom: 0;
    }

    >ul {
        >li {
            padding: 10px 0;
            width: 100%;
            text-align: left;
            color: $color-white;
            font-family: $base-font-family;
            font-weight: 700;
            @include font-size(15);
            border-bottom: 1px solid $color-white;
        }
    }

    &--unstyled {
        >ul {
            >li {
                border-bottom: 0;
            }
        }

        a {
            font-weight: 600;
        }
    }
}