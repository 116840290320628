ul,
ol,
dl,
dd,
blockquote,
p,
address,
hr,
table,
fieldset,
figure,
pre {
    margin-bottom: 1em;
}

strong {}

a {
    color: $brand-blue;
    text-decoration: none;

    &:hover,
    &:before,
    &:after {
        text-decoration: underline;
    }
}

ul,
ol {
    list-style: none;
}

blockquote {
    border-left: 5px solid #000000;
    padding: 1em;

    p {
        margin: 0;

        &:before {
            content: open-quote;
        }
    }

    cite {
        font-style: italic;
    }
}