/* Page Manager content */

.pm-content {
    ul {
        margin-bottom: 0;

        li {
            position: relative;
            padding: 0 0 1em 1.5em;

            &:before {
                content: '';
                background-color: blue;
                border-radius: 50%;
                position: absolute;
                top: 7px;
                left: 2px;
                height: 8px;
                width: 8px;
            }

            li {
                &:before {
                    border-radius: 0;
                }

                &:first-child {
                    margin-top: 1em;
                }
            }
        }
    }

    ol {
        counter-reset: item;
        
        li {
            padding-bottom: 1em;

            &:before {
                content: counters(item, ".") ". ";
                counter-increment: item;
            }
        }

        ol {
            margin-left: 1.5em;

            li:first-child {
                margin-top: 1em;
            }
        }
    }
}
