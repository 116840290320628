.c-panel {
    $root: &;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include min($width-tablet-sm) {
        padding: 1rem 0;
    }

    &__inner {
        width: 100%;
        margin-bottom: 2rem;
        text-align: center;

        &:last-child {
            margin-bottom: 0;
        }

        @include min($width-tablet-sm) {
            width: 50%;
            margin-bottom: 0;
            padding-bottom: 4rem;

            &:nth-child(2n + 1) {
                padding-right: 2rem;
            }

            &:nth-child(2n) {
                padding-left: 2rem;
            }

            &:last-child,
            &:nth-last-child(2) {
                padding-bottom: 0;
            }
        }
    }

    &__box {
        position: relative;
        height: 100%;
        min-height: 250px;
        padding: 2rem;
        background-color: $color-white;

        @include min($width-tablet-sm) {
            padding: 2.5rem;
        }

        @include min($width-tablet-lg) {
            min-height: 0;
        }

        &--grey-light {
            background-color: rgba($color-black, 0.05);

            #{$root}__title {
                h2 {
                    color: $color-black;
                }

                &:after {
                    background-color: $color-black;
                }
            }
        }

        &--blue {
            background-color: $brand-blue;

            #{$root}__title {
                h2 {
                    color: $color-white;
                }
            }
        }

        &--pink {
            background-color: $brand-pink;

            #{$root}__title {
                h2 {
                    color: $color-white;
                }
            }
        }

        &--purple {
            background-color: $brand-purple;

            #{$root}__title {
                h2 {
                    color: $color-white;
                }
            }
        }

        &--aqua {
            background-color: $brand-aqua;

            #{$root}__title {
                h2 {
                    color: $color-white;
                }
            }
        }

        &--orange {
            background-color: $brand-orange;

            #{$root}__title {
                h2 {
                    color: $color-white;
                }
            }
        }

        &--orange-deep {
            background-color: $color-orange-deep;

            #{$root}__title {
                h2 {
                    color: $color-white;
                }
            }
        }

        &--green {
            background-color: $brand-green;

            #{$root}__title {
                h2 {
                    color: $color-white;
                }
            }
        }

        &--green-deep {
            background-color: $color-green-deep;

            #{$root}__title {
                h2 {
                    color: $color-white;
                }
            }
        }

        &--shade {
            background-color: rgba(#54575A, 0.05);

            #{$root}__title {
                h2 {
                    color: $color-blue-aqua;
                }

                &:after {
                    background-color: $color-black;
                }
            }
        }

        &--white {
            background-color: $color-white;

            #{$root}__title {
                h2 {
                    color: $color-blue-aqua;
                }

                &:after {
                    background-color: $color-black;
                }
            }
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        // align-items: center;
        height: 100%;

        @include min($width-tablet-sm) {
            display: block;
            align-items: unset;
            padding-bottom: 7rem;
        }

        @include min($width-tablet-lg) {
            display: flex;
        }
    }

    &__title {
        position: relative;
        width: 100%;
        margin-bottom: 2.2rem;
        padding: 2rem 0;
        align-self: flex-end;
        color: $color-blue-aqua;

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            height: 1px;
            max-width: 106px;
            width: 100%;
            background-color: $color-white;
        }

        h2 {
            margin-bottom: 0;
            letter-spacing: 0;
        }

        @include min($width-tablet-sm) {
            margin-bottom: 2rem;
            align-self: unset;
        }

        @include min($width-tablet-lg) {
            align-self: flex-end;
        }
    }

    &__cta {
        width: 100%;
        align-self: flex-start;

        .c-button {
            min-width: 143px;
        }

        @include min($width-tablet-sm) {
            align-self: unset;
            position: absolute;
            bottom: 1.4rem;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }
}