/*
    Settings: COLORS

    The color palette is where colors across the site are set.
    Here are the rules:
        -   Only reference specific hexadecimal colors within the Color Palette area of this partial.
        -   Brand colors should be prefixed with $brand-

*/

/* Base colors */

$color-black: #000000;
$color-white: #ffffff;

$color-red:red;
$color-blue:blue;

$color-grey: #707070;
$color-grey-dark: #54575A;
$color-blue-aqua: #08B0DC;
$color-blue-pacific: #05B5B0;
$color-green-deep: #007051;
$color-purple: #6A2383;
$color-orange-deep: #FD7500;

/* Brand colors */

$brand-orange: #FF8A26;
$brand-orange-dark: #B46800;
$brand-aqua: #54C0D3;
$brand-aqua-dark: #4092A0;
$brand-blue: #007CC5;
$brand-blue-dark: #005790;
$brand-purple: #502795;
$brand-purple-dark: #461657;
$brand-green: #78A334;
$brand-green-dark: #557424;
$brand-yellow: #CDCE00;
$brand-black-light: #413D4C;
$brand-pink: #F448A3;


/* Global colors */

$color-text: $color-black;