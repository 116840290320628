// Dropdowns
.RadDropDownList_rt {
    border: 1px solid #000000;

    .rddlInner {
        position: relative;
        padding-right: 30px;
    }

    .rddlFakeInput {
        display: block;
        overflow: hidden;
    }

    .rddlIcon {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -4px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 6px 0 6px;
        border-color: #000000 transparent transparent transparent
    }

    input[type=text],
    input[type=email],
    input[type=number],
    input[type=password],
    input[type=search],
    input[type=url],
    input[type=tel],
    select,
    textarea,
    .rddlInner {
        @include font-size(20);
        border-radius: 0;
        display: block;
        padding: 1rem;
        margin: 0;
        width: 100%;
        line-height: 1.6;
        appearance: none;
    }
}

.rddlSlide {
    display: none;
    position: absolute;
    overflow: hidden;
    z-index: 7000;
}

.rddlPopup {
    background: #ffffff;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-left: 1px solid #000000;
    position: relative;

    .rddlList {
        position: relative;
        margin: 0;
        height: 100%;
        overflow: auto;
    }

    .rddlItem {
        padding: 5px 10px;
    }

    .rddlItemHovered {
        color: #ffffff;
        background-color: $brand-blue;
    }
}

// DOB Fields
.date-droplist {
    display: inline-block !important;
    width: 100%;

    .sid-fdDateOfBirth-Day,
    .sid-fdDateOfBirth-Month,
    .sid-fdDateOfBirth-Year {
        &:focus {
            outline: 0;
        }
    }

    .date-droplist-child {
        display: inline-block;
        margin-right: 2rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .rddlInner {
        display: inline-block;
        position: relative;
        width: 100%;
    }

    .rddlFakeInput {
        display: inline-block;
        width: 100%;
        padding: 10px 36px 10px 0px;
    }

    .rddlIcon {
        display: inline-block;
        position: absolute;
        width: 36px;
        height: 100%;
        top: 0;
        right: 0;
        padding: 1.4rem 0;
        pointer-events: none;
        background-image: url("/images/icons/icon-arrow-dropdown-chervon-blue.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 24px;
    }
}