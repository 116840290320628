.c-confirmation-message {
    $root: &;
    max-width: 600px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 0 6rem;
    text-align: center;

    &__icon {
        padding: 2rem 0;

        .c-icon {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__header {
        padding: 2rem 0;
    }

    &__title {
        margin-bottom: 0.5rem;
    }

    &__content {
        padding-top: 2rem;

        @include min($width-tablet-sm) {
            padding-top: 3rem;
        }
    }

    h4 {
        margin-bottom: 2rem;
        color: $color-blue-aqua;
    }

    &--aqua {
        #{$root}__icon {
            fill: $brand-aqua;
        }

        #{$root}__title {
            color: $brand-aqua;
        }
    }

    &--orange {
        #{$root}__icon {
            fill: $brand-orange;
        }

        #{$root}__title {
            color: $brand-orange;
        }
    }

    &--green {
        #{$root}__icon {
            fill: $brand-green;
        }

        #{$root}__title {
            color: $brand-green;
        }
    }
}