.c-obituaries {
    $root: &;
    display: inline-block;
    width: 100%;

    &__inner {
        width: 100%;
    }

    &__title {
        padding-top: 3rem;
        text-align: center;
        color: $brand-aqua;

        @include min($width-tablet-sm) {
            margin: 2rem 0;
            padding-top: 1.4rem;
        }
    }

    &__item {
        position: relative;
        width: 100%;
        padding: 3rem 0;
        text-align: center;

        h4 {
            margin-bottom: 1rem;
            color: $brand-aqua;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__item-inner {
        padding: 0 2rem;

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            height: 1px;
            width: 90%;
            background-color: $color-grey;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        &__row {
            &:nth-child(2n) {
                #{$root}__item {
                    &:nth-child(2n) {
                        #{$root}__item-inner {
                            border-right: 1px solid $color-grey;
                        }
                    }
                }
            }

            &:nth-child(2n + 1) {
                #{$root}__item {
                    &:nth-child(2n + 1) {
                        #{$root}__item-inner {
                            border-right: 1px solid $color-grey;
                        }
                    }
                }
            }
        }

        &__item {
            width: 50%;
            border-bottom: 1px solid $color-grey;
        }
    }

    @include min($width-tablet-sm) {
        &__item {
            float: left;
        }

        &__item-inner {
            &:after {
                content: none;
            }
        }
    }

    @include min($width-tablet-lg) {
        &__row {
            &:nth-child(2n) {
                background-color: rgba($color-grey, 0.05);
            }
        }

        &__inner-row {
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            border-bottom: 0;
            @include colNum(4);

            &:nth-child(2n + 1) {
                #{$root}__item-inner {
                    border-right: 1px solid $color-grey;
                }
            }

            &:nth-child(2n) {
                #{$root}__item-inner {
                    border-right: 1px solid $color-grey;
                }
            }

            &:nth-child(3n) {
                #{$root}__item-inner {
                    border-right: 0;
                }
            }
        }
    }
}