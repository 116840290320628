.c-registration {
    position: relative;
    width: 100%;
    max-width: 645px;
    margin: 0 auto;
    padding: 2rem 0;
    background-color: $color-white;

    @include min($width-tablet-sm) {
        padding: 3rem 0 1rem 0;
    }

    h2 {
        display: inline-block;
        color: $color-blue-aqua;
        margin-bottom: 0.5rem;
    }

    a:not(.c-button) {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &__help-link {
        position: absolute;
        top: 8px;
        right: 0;
        display: inline-block;
    }

    &__message {
        padding-top: 1.6rem;
    }

    &__highlight {
        color: #EC6607;
        font-weight: 500;
    }
}