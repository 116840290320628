.c-news-list {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    text-align: center;

    ul {
        font-size: 0;

        > li {
            display: inline-block;
            @include colNum(12);
            padding: $base-gutter calc($base-gutter/3);

            @include min(645px) {
                padding: $base-gutter $base-gutter;
            }
        }
    }



    @include min($width-tablet-sm) {
        ul {

            > li {
                float: left;
                @include colNum(6);
                padding: $base-gutter;
                overflow: hidden;
            }
        }
    }

    @include min($width-desktop) {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            > li {
                @include colNum(4);
            }
        }

        li {
            max-width: 436px;
        }
    }

    &__title {
        text-align: center;
    }

    &__cta {
        width: 100%;
        display: inline-block;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .c-news-list-item {
        border: 1px solid #B3B5B7;
    }
}

.c-news-list-item {
    $root: &;
    position: relative;
    display: block;
    @include colNum(12);
    height: 100%;
    padding: 0;
    text-align: center;
    background-color: $brand-aqua;
    color: $color-white;

    &.c-alert-button {
        background-color: transparent;
        border: none;
    }

    &:hover {
        outline: 5px solid #FF8A26;
        text-decoration: none;
    }

    &__image-inner {
        height: 100%;
    }

    &__image {
        display: block;
        position: relative;
        transition: border 0.2s ease-in-out;
        overflow: hidden;
        background-color: $color-white;

        img {
            max-height: 100%;
            width: 100%;
            display: block;
            margin: 0 auto;
            min-height: 100%;
        }
    }

    &__content {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 6rem;
        padding-top: 1.4rem;
    }

    &__title {
        padding-top: 1.2rem;

        h6 {
            color: $color-white;
            text-align: center;
            margin-bottom: 0;
            @include font-size(20);
        }
    }

    &__date {
        position: absolute;
        bottom: 1.6rem;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        font-weight: 500;
        @include font-size(18);
    }

    &__cta {
        position: absolute;
        bottom: 1.6rem;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        .c-button {
            min-width: 179px;
        }
    }

    @include min($width-tablet-sm) {
        display: flex;
        flex-wrap: wrap;

        &__image {
            height: 100%;
            padding-bottom: 135px;
            width: 100%;

            img {
                margin: auto;
                height: 100%;
            }
        }

        &__content {
            min-height: 140px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            background-color: $brand-aqua;
            width: 100%;
        }
    }

    &-nohover {
        &:hover {
            outline: none !important;
            text-decoration: none;
        }
    }
}
