﻿.c-list-expander {
    $root: &;

    &__detatched-toggle {
        display: flex;
        justify-content: space-between;
    }

    &__toggle-btn {
        background-color: transparent;
        border: 0;
        padding: 0.9rem 2.6rem;
        outline: none;

        &:before,
        &:after {
            content: '';
            background-color: $brand-blue;
            position: absolute;
            top: 50%;
        }

        &:before {
            right: 24px;
            margin-top: 0;
            height: 0;
            width: 4px;
            transition: opacity 0.2s ease-in-out;
        }

        &:after {
            right: 16px;
            margin-top: -2px;
            height: 4px;
            width: 20px;
        }
    }

    &__link {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
            height: 18px;
            width: 1px;
            background-color: #ccc;
        }
    }

    &__view {
        max-height: 0;
        transition: max-height 0.2s ease-in-out;
        overflow: hidden;

        > ul {
            > li {
                a {
                    font-weight: 300 !important;
                    padding: 0.7rem 1.8rem 0.7rem 4rem !important;
                }
            }
        }
    }

    &--hidden {
        #{$root}__toggle-btn {
            &:before {
                margin-top: -10px;
                height: 20px;
            }
        }
    }
}
