.c-hero-box {
    position: relative;
    width: 100%;
    color: $color-white;
    background-color: $color-purple;
    text-align: center;
    margin-bottom: 34px;

    @include min($width-tablet-sm) {
        text-align: left;
    }

    &__inner {
        padding: 0;
    }

    &__box {
        position: relative;
        padding: 0 2rem;
    }

    &__content {
        width: 100%;

        h3 {
            color: $color-white;
            margin-bottom: 0.5em;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__cta {
        width: 100%;
        margin-top: 2rem;

        .c-button {
            min-width: 114px;
        }
    }

    @include min($width-tablet-sm) {
        &__box {
            padding: 0 16rem 0 3rem;
        }

        &__content,
        &__cta {
            display: inline-block;
            width: calc(100% - 114px);
            vertical-align: top;
        }

        &__cta {
            position: absolute;
            width: auto;
            top: 50%;
            right: 2rem;
            transform: translateY(-50%);
            margin-top: 0
        }
    }

    .h3larger {
        @include font-size(30);
    }
}

.blue-hero-box {
    background-color: $color-blue-aqua;
}
