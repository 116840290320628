﻿%e-input {
    display: block;
    @include font-size(18);
    width: 100%;
    padding: 1.4rem 1rem;
    margin: 0;
    border: 1px solid $color-grey;
    border-radius: 0;
    appearance: none;
}

label {
    display: block;
    line-height: 1.2;
    font-weight: 700;
}

input[type=text],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=url],
input[type=tel],
select,
textarea {
    @extend %e-input;

    &:focus {
        outline: none;
    }
}

select::-ms-expand {
    display: none;
}

textarea {
    min-height: 9em;
    resize: vertical;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
    color: grey;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-moz-outer-spin-button,
input[type=number]::-moz-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

// %e-button {
//     display: inline-block;
//     position: relative;
//     line-height: 1;
//     text-decoration: none;
//     text-align: center;
//     cursor: pointer;
//     padding: 1rem 2rem;
//     border: 0;
//     border-radius: 0;
//     appearance: none;

//     &:hover {
//         text-decoration: none;
//     }
// }

button,
input[type=button],
input[type=submit] {
    // @extend %e-button;
    position: relative;
    cursor: pointer;
    line-height: 1.2;
    appearance: none;
}

%e-button {
    position: relative;
    display: inline-block;
    min-width: 179px;
    padding: 10px 20px;
    border-radius: 14px;
    font-family: $base-font-family;
    font-weight: 500;
    background-color: $brand-blue;
    color: $color-white;
    border: 2px solid $brand-blue;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out, color 0.3s ease-in-out;

    &:hover {
        background-color: $brand-blue-dark;
        color: $color-white;
        border-color: $brand-blue-dark;
        text-decoration: none;
    }
}

// button {
//     @extend %e-button;
// }