/*%cf {
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}*/

.o-site-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.pm-editmode {
    .o-site-wrapper {
        display: table;
    }
}

.o-outer-page {
    position: relative;
    transition: margin-left 0.5s ease;
    height: 100%;
    z-index: 2;
    width: 100%;

    &.nav-shown {
        margin-left: -364px;
        position: fixed;
    }
}

.o-main-content {
    position: relative;
    min-height: 100%;
    margin-bottom: -44px;
    padding-bottom: 44px;
    background-color: $color-white;

    &--shaded {
        background-color: #F8F8F8;
    }
}

.o-content-footer {
    min-height: 44px;
}

.o-wrap {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
}

.o-flex {
    display: flex;
    flex-wrap: wrap;
}

.o-content-wrap {
    margin: 0 auto;
    width: 100%;
    padding: 2rem 0;

    @include min($width-tablet-sm) {
        padding: 3rem 0;
    }
}

.o-gutter {
    padding: 0 2rem;

    @include min($width-tablet-sm) {
        padding: 0 3rem;
    }
}

.o-strip {
    $root: &;

    &--shade {
        background-color: #F6F6F6;
    }

    &--spacing {
        padding: 4rem 0;

        @include min($width-tablet-sm) {
            padding: 6rem 0;
        }
    }
}