.c-contact {
    $root: &;
    margin-top: 2rem;
    padding: 2rem 0;

    &__inner {
        display: flex;
        flex-wrap: wrap;
    }

    &__section {
        width: 100%;
        padding: 1.7rem 0 0 0;


        &:nth-child(2n) {
            padding-right: 0;
        }

        &:nth-child(2n + 1) {
            padding-left: 0;
        }

        @include min($width-tablet-lg) {
            width: 50%;
            padding: 1.5rem;
        }
    }

    &__row {
        &:last-child {
            ul {
                &:last-child {
                    margin-bottom: 0;
                }
            }

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__enhanced {
        font-weight: 700;
    }

    &__title {
        margin-left: -0.05em;
        margin-bottom: 0.5rem;
        letter-spacing: 1px;
    }

    &--aqua {
        #{$root}__title {
            color: $brand-aqua;
        }

        #{$root}__enhanced {
            color: $brand-aqua;
        }
    }

    &--orange {
        #{$root}__title {
            color: $brand-orange;
        }

        #{$root}__enhanced {
            color: $brand-orange;
        }
    }

    &--purple {
        #{$root}__title {
            color: $brand-purple;
        }

        #{$root}__enhanced {
            color: $brand-purple;
        }
    }
}