.c-grid-side-image {
    @include min(1300px) {
        /*background-image: url("/images/template/home-grid-placeholder.jpg");*/
        background-image: url("/images/template/home-grid-placeholder-v2.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right center;
        width: 508px;
        min-height: 100%;
        width: 50%;
    }
}
