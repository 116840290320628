html {
    font-size: 62.5%;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

body {
    position: relative;
    color: $color-text;
    @include font-size(16);
    font-family: $base-font-family;
    line-height: #{$base-line-height};
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    // @include min($width-tablet-sm) {
    //     @include font-size(18);
    // }
}
