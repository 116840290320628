.media-item {
    /*@extend %cf;*/
    padding: 40px 0;

    img {
        display: block;
        margin-right: 60px;

        @include max(750px) {
            margin-right: 20px;
        }

        @include min(501px) {
            float: left;
            max-width: 40%;
        }

        @include max(500px) {
            margin: 0 auto 20px auto;
        }
    }

    &__content {
        overflow: hidden;
    }

    .media-list & {
        border-top: 1px solid #000000;

        &:first-child {
            border-top: 0;
            padding-top: 20px;
        }
    }
}