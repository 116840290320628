.c-themed-list {
    $root: &;
    position: relative;
    padding-bottom: 2rem;

    > ul {
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding-left: 58px;

        > li {
            position: relative;
            padding-top: 0.6rem;
            padding-bottom: 0.6rem;
            padding-left: 30px;

            &:before {
                content: "";
                position: absolute;
                top: 1rem;
                left: 0;
                width: 11px;
                height: 11px;
                background-color: $color-black;
                border-radius: 50%;
            }
        }
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__icon {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        margin-right: 0.6rem;
        padding: 0.9rem;
        background-color: $color-black;

        .c-icon {
            width: 100%;
            height: 100%;
        }
    }

    &__title {
        width: calc(100% - 64px);
        padding: 1.4rem 1.2rem;
        border-bottom: 3px solid $color-black;
        color: $color-black;
        font-weight: 700;
    }

    &--purple {
        #{$root}__icon {
            background-color: $brand-purple;
        }

        #{$root}__title {
            color: $brand-purple;
            border-bottom-color: $brand-purple;
        }

        > ul {
            > li {
                &:before {
                    background-color: $brand-purple;
                }
            }
        }
    }

    &--orange {
        #{$root}__icon {
            background-color: $brand-orange;
        }

        #{$root}__title {
            color: $brand-orange;
            border-bottom-color: $brand-orange;
        }

        > ul {
            > li {
                &:before {
                    background-color: $brand-orange;
                }
            }
        }
    }

    &--green {
        #{$root}__icon {
            background-color: $brand-green;
        }

        #{$root}__title {
            color: $brand-green;
            border-bottom-color: $brand-green;
        }

        > ul {
            > li {
                &:before {
                    background-color: $brand-green;
                }
            }
        }
    }

    &--green-deep {
        #{$root}__icon {
            background-color: $color-green-deep;
        }

        #{$root}__title {
            color: $color-green-deep;
            border-bottom-color: $color-green-deep;
        }

        > ul {
            > li {
                &:before {
                    background-color: $color-green-deep;
                }
            }
        }
    }

    &--blue {
        #{$root}__icon {
            background-color: $brand-blue;
        }

        #{$root}__title {
            color: $brand-blue;
            border-bottom-color: $brand-blue;
        }

        > ul {
            > li {
                &:before {
                    background-color: $brand-blue;
                }
            }
        }
    }
}
