﻿.c-site-footer {
    position: relative;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    background-color: $color-blue-pacific;

    &__copyright {
        display: inline-block;
        @include font-size(11);
        font-weight: 600;
        margin-top: 0.6rem;
        margin-bottom: 1rem;

        @include min($width-tablet-sm) {
            @include font-size(14);
            margin-bottom: 0;
        }
    }

    &__nav {
        display: inline-block;
        margin-left: 0.5rem;
        @include font-size(11);

        @include min($width-tablet-sm) {
            @include font-size(14);
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
            justify-content: center;
        }

        li {
            padding: 0 10px;
            border-left: 2px solid $color-white;
        }

        a {
            display: block;
            font-weight: 700;
            color: $color-white;
        }
    }

    &__block {
        display: inline-block;
        min-height: 44px;

        &--content {
            width: 100%;
            margin: 0 auto;
            padding: 0.8rem 2rem;
            text-align: center;
            background-color: $brand-black-light;
            color: $color-white;
        }

        &--green {
            background-color: $brand-green;
        }

        &--green-deep {
            background-color: $color-green-deep;
        }

        &--purple {
            background-color: $color-purple;

            @include max(1625px) {
                display: none;
            }
        }

        &--aqua {
            background-color: $brand-aqua;

            @include max(1205px) {
                display: none;
            }
        }

        &--aqua:nth-child(6) {
            @include max(1485px) {
                display: none;
            }
        }

        &--yellow {
            background-color: $brand-yellow;

            @include max(1345px) {
                display: none;
            }
        }

        @include max(900px) {
            display: none;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                display: block;
            }

            &:nth-child(1),
            &:nth-child(3) {
                min-width: 10%;
            }
        }

        @include min($width-tablet-sm) {
            min-width: 140px;

            &:first-child {
                min-width: 10%;
            }

            &--content {
                min-width: 500px;
                // width: 400px;
                // margin: 0;
                max-width: none;
                text-align: left;
                //white-space: nowrap;
            }
        }
    }
}

.o-outer-page--landing {
    .c-site-footer {
    }
}
