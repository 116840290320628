﻿#HomePagePopupModal {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.45);
    top: 0;
    left: 0;
    opacity: 0.8;
    position: fixed;
    z-index: 99;
}

#HomePagePopup {
    max-width: 450px;
    height: auto;
    border: 2px solid #30BFE1;
    position: fixed;
    width: 100%;
    padding: 2rem;
    background-color: #ffffff;
    z-index: 99;
    font-size: 16px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);


    p.blue {
        color: #30BFE1;
        font-weight: bold;
    }
}

.home-page-popup__CTA {
    text-align: center;

    .c-button {
        min-width: 160px;
        font-size: 13px;
        margin: 5px 10px;
        background-color: #30BFE1;
        border-color: #30BFE1;
        cursor: pointer;
    }

    .c-button:hover {
        background-color: #005790;
        border-color: #005790;
    }
}
