.c-cookie-bar {
    position: absolute;
    height: auto;
    bottom: 0;
    width: 100%;
    padding: 2rem;
    background-color: #ffffff;
    border: 1px solid #000000;

    @include min($width-tablet-lg) {
        max-width: 410px;
        bottom: 2rem;
        left: 2rem;
        padding: 3rem;
    }

    &__hidden,
    &--hidden {
        height: 0;
        width: 0;
        padding: 0;
        margin: 0;
        border: 0;
        overflow: hidden;
    }

    &__message {
        margin-bottom: 1rem;

        @include min($width-tablet-lg) {
            margin-bottom: 2rem;
        }
    }

    &__ctas {
        ul {
            display: flex;
            flex-wrap: nowrap;
            font-size: 0;
        }

        li {
            display: inline-block;

            &:first-child {
                flex-grow: 1;
            }
        }
    }
}
