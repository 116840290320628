.c-form {
    $root: &;
    padding-bottom: 0.6rem;

    @include min($width-tablet-sm) {
        padding-bottom: 2rem;
    }

    &--aqua {
        label {
            color: $brand-aqua;
        }
    }

    &--orange {
        label {
            color: $brand-orange;
        }
    }

    .c-button {
        @include font-size(18);
        min-width: 143px;
    }
}