.c-site-opts {
    $root: &;
    display: none;

    @include min($width-tablet-lg) {
        display: block;
    }

    &__area {
        display: flex;
        flex-wrap: wrap;
        color: $color-blue-aqua;

        &:hover {
            text-decoration: none;

            #{$root}__icon-wrap {
                .c-icon {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &__icon-wrap {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 4px 4px 8px 4px;
        width: 37px;
        height: 50px;
        background-color: $color-blue-aqua;

        .c-icon {
            width: 18px;
            height: 18px;
            fill: $color-white;
            transition: transform 0.3s ease-in-out;
        }
    }

    &__text {
        display: flex;
        align-self: flex-end;
        margin-left: 6px;
        padding: 4px;
        font-weight: 700;
        @include font-size(17);
        text-transform: uppercase;
    }

    &--blend-aqua,
    &--blend-aqua-blue {
        #{$root}__area {
            color: $color-white;
        }

        #{$root}__icon-wrap {
            background-color: $color-white;

            .c-icon {
                fill: $color-blue-aqua;
            }
        }
    }

    &--blend-purple {
        #{$root}__area {
            color: $color-white;
        }

        #{$root}__icon-wrap {
            background-color: $color-white;

            .c-icon {
                fill: $brand-purple;
            }
        }
    }

    &--blend-orange {
        #{$root}__area {
            color: $color-white;
        }

        #{$root}__icon-wrap {
            background-color: $color-white;

            .c-icon {
                fill: $brand-orange;
            }
        }
    }

    &--blend-green {
        #{$root}__area {
            color: $color-white;
        }

        #{$root}__icon-wrap {
            background-color: $color-white;

            .c-icon {
                fill: $brand-green;
            }
        }
    }
}