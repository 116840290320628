.c-tabs {
    $root: &;
    padding-top: 2rem;

    @include min($width-tablet-sm) {
        padding-top: 3.2rem;
    }

    &__buttons-wrap {
        position: relative;
        margin-bottom: 3rem;

        @include min($width-tablet-sm) {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
        }
    }

    &__button {
        width: 100%;
        min-width: 0;
        font-weight: 700;
        transition: none;
        border: 0;
        border-radius: 0;
        background-color: $color-white;
        color: $color-black;
        text-align: left;
        @include font-size(20);
        padding: 0.8rem 2rem;

        &:hover {
            background-color: $color-white;
            color: $color-black;
            text-decoration: underline;
        }

        &:focus {
            outline: 0;
        }

        @include min($width-tablet-sm) {

            width: auto;
            padding: 0.4rem 4rem;
            text-align: center;

            &:hover,
            &.active {
                background-color: rgba($color-black, 0.05);
                color: $color-black;
            }

            &:hover {
                text-decoration: none;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -1px;
                width: 1px;
                height: 100%;
                background-color: $color-grey;
                pointer-events: none;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: -1px;
                width: 1px;
                height: 100%;
                background-color: transparent;
                pointer-events: none;
            }

            &:last-child {
                &:after {
                    background-color: $color-grey;
                }
            }

            &--orange {
                color: $brand-orange;

                &:hover,
                &.active {
                    background-color: rgba($brand-orange, 0.05);
                    color: $brand-orange;
                }
            }

            &--aqua {
                color: $brand-aqua;

                &:hover,
                &.active {
                    background-color: rgba($brand-aqua, 0.05);
                    color: $brand-aqua;
                }
            }
        }

        @include min($width-tablet-lg) {
            @include font-size(24);
            padding: 0.4rem 7rem;
        }
    }

    &__view {}

    &__hidden {
        display: none;
    }
}