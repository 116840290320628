.c-overlay-panel {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0%;
    width: 0%;
    z-index: 6;
    pointer-events: none;
    transition: background-color 0.2s ease-in-out;
    overflow: hidden;

    &--visible {
        width: 100%;
        height: 100%;
        pointer-events: all;
        background-color: rgba($color-black, 0.8);
        overflow: auto;
        padding: 60px 0;
    }

    &__wrap {
        max-width: 1107px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    &__inner {
        padding-bottom: 2rem;
        background-color: $color-white;
        border: 2px solid $brand-aqua;

        &--loader {
            background-image: url("/images/icons/ajax-loader.gif");
            background-position: center center;
            background-size: 28px 28px;
            background-repeat: no-repeat;
        }
    }

    &__banner-image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 36%;
    }

    &__content {
        padding: 1rem 2rem;

        @include min($width-tablet-sm) {
            padding: 2rem 4rem;
        }

        @include min($width-tablet-lg) {
            padding: 2.8rem 5rem;
        }
    }

    &__title {
        h3 {
            color: $brand-aqua;
            font-family: $base-font-family;
        }
    }

    &__text {
        font-family: $base-font-family;
    }

    &__cta {
        padding-top: 4rem;
        text-align: center;
    }
}
