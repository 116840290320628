.c-account {
    position: relative;
    width: 100%;
    max-width: 1208px;
    margin: 0 auto;
    padding: 2rem 0 0 0;
    background-color: $color-white;

    @include min($width-tablet-sm) {
        padding: 3rem 0 0 0;
    }

    h2 {
        display: inline-block;
        color: $color-blue-aqua;
        margin-bottom: 0.5rem;
    }

    a:not(.c-button) {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &__inner {
        width: 100%;
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
        padding: 1.6rem 0;

        @include min($width-tablet-lg) {
            padding: 2.6rem 2rem;
        }
    }

    &__help-link {
        position: absolute;
        top: 8px;
        right: 0;
        display: inline-block;
    }
}