.c-form-field {
    $root: &;
    padding-top: 1.2rem;
    margin-bottom: 1rem;

    &--no-label {
        padding-top: 3rem;

        &:first-of-type {
            padding-top: 2rem;
        }
    }

    label,
    input {
        display: inline-block;
        width: 100%;
    }

    label {
        padding-bottom: 1rem;
    }

    &--inline {

        label,
        input {
            width: auto;
        }

        label {
            padding-right: 1rem;
        }
    }

    &__errors-container {
        display: none;
    }

    &--error {
        #{$root}__errors-container {
            display: block;
            width: 100%;
            margin-top: 1rem;
        }

        label {
            color: red;
        }

        input {
            border-color: red;
        }
    }

    &--checkbox,
    &--radio {

        input[type=checkbox],
        input[type=radio] {
            position: absolute;
            left: -9999px;

            +label {
                position: relative;
                padding: 1rem 1rem 1rem 3.5rem;

                &:before {
                    content: '';
                    background-color: $color-white;
                    border: 2px solid $color-black;
                    position: absolute;
                    top: 9px;
                    left: 0px;
                    height: 23px;
                    width: 23px;
                }
            }
        }
    }

    &--checkbox {
        input[type=checkbox] {
            &:checked+label:after {
                content: '';
                position: absolute;
                padding: 0;
                background-image: url("/images/icons/tick.svg");
                background-size: 100%;
                background-repeat: no-repeat;
                width: 29px;
                height: 27px;
                top: 6px;
                left: -3px;
            }
        }
    }

    &--radio {
        input[type=radio] {
            &:checked+label:after {
                content: '';
                position: absolute;
                padding: 0;
                background-color: $color-black;
                width: 13px;
                height: 13px;
                top: 14px;
                left: 5px;
            }
        }

        input[type=radio] {
            +label {
                margin-right: 30px;

                &:before {
                    border-radius: 50%;
                }
            }

            &:checked+label:after {
                border-radius: 50%;
                content: '';
                position: absolute;
                padding: 0;
                background-color: $color-black;
                width: 13px;
                height: 13px;
                top: 14px;
                left: 5px;
            }
        }
    }
}

.c-form-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;

    &__inner {
        width: 100%;
        text-align: center;
        padding-top: 1.4rem;

        &--password-link {
            text-align: right;
        }

        .c-button {
            margin-top: 1.4rem;
        }
    }

    @include min($width-tablet-sm) {
        &__inner {
            width: auto;

            &--password-link {
                margin-bottom: 0;
            }
        }
    }

    &--centered {
        justify-content: center;
    }

    &--spaced {
        justify-content: space-between;
    }
}

.c-form-cta {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;

    &__inner {
        width: 100%;
        text-align: center;
        padding-top: 1.4rem;

        .c-button {
            margin-top: 1.4rem;
        }
    }

    @include min($width-tablet-sm) {
        &__inner {
            width: auto;
        }
    }

    &--centered {
        justify-content: center;
    }

    &--spaced {
        justify-content: space-between;
    }
}