h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
}

%e-h1 {
    @include font-size(35);
    margin-bottom: 2rem;
    letter-spacing: 1px;
    color: $color-blue-aqua;

    @include min($width-tablet-sm) {
        @include font-size(44);
        margin-bottom: 2.5rem;
    }
}

%e-h2 {
    @include font-size(23);
    margin-bottom: 1.5rem;
    letter-spacing: 1px;
    color: $color-blue-aqua;

    @include min($width-tablet-sm) {
        @include font-size(27);
        margin-bottom: 2rem;
    }
}

%e-h3 {
    @include font-size(21);
    margin-bottom: 1rem;
    letter-spacing: 1px;

    @include min($width-tablet-sm) {
        @include font-size(23);
    }
}

%e-h4 {
    @include font-size(19);
    margin-bottom: 1rem;

    @include min($width-tablet-sm) {
        @include font-size(21);
    }
}

%e-h5 {
    @include font-size(17);

    @include min($width-tablet-sm) {
        @include font-size(19);
    }
}

%e-h6 {
    @include font-size(16);

    @include min($width-tablet-sm) {
        @include font-size(17);
    }
}

h1 {
    @extend %e-h1;
}

h2 {
    @extend %e-h2;
}

h3 {
    @extend %e-h3;
}

h4 {
    @extend %e-h4;
}

h5 {
    @extend %e-h5;
}

h6 {
    @extend %e-h6;
}